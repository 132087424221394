$header-height-large:  rem(375px);
$header-height-medium: rem(200px);
$header-height-small:  rem(115px);
header.page-header {
    z-index: 1;
    height: $header-height-large;
    @include clearfix;
    @include mq(desktop) {};

    & > .grid-container {
        min-height: $header-height-large;
        background: url($baseUrl + 'shapes/shape-header.svg') 0 0 / 91% auto no-repeat;
    }

    @media screen and (min-width: 1200px) and (max-width: 1500px) {
        height: rem(250px);

        & > .grid-container {
            min-height: rem(250px);
            background-position: 50% 0;
            background-size: 63% auto;
        }
    }

    @include breakpoint(medium only) {
        height: $header-height-medium;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 30%, transparent 30%, transparent 100%);
        & > .grid-container {
            min-height: $header-height-medium;
        }
    }
    @include breakpoint(small only) {
        height: $header-height-small;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 52%, transparent 52%, transparent 100%);
        & > .grid-container {
            min-height: $header-height-small;
            background: none;
        }
    }
}

// Logo
.top-logo {
    width: rem(241px);
    @include abs (rem(41px),rem(45px),auto,auto);

    @include breakpoint(medium down) {
        width: rem(170px);
        z-index: 101;
        @include abs (rem(10px),rem(15px),auto,auto);

        &.open {
            position: fixed;
        }
    }

    &__404 {
        padding: rem(48px) 0 rem(21px);
        text-align: center;
        @include clearfix;
        a {
            width: rem(241px);
            display: inline-block;
        }
        @include breakpoint(medium down) {
            a {
                width: rem(170px);
            }
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1500px) {
        left: rem(25px);
    }
}

// Menu
.main-menu {
    padding-top: rem(32px);
    text-align: center;
    letter-spacing: -0.3em;

    & > li {
        padding: 0 rem(45px);
        display: inline-block;
        font-size: rem(22px);
        font-family: $exo-l;
        letter-spacing: normal;
        position: relative;
        & > a {
            position: relative;
            z-index: 5;
            @include link-color (#333,#2181c4);
            @include link-decoration (nn);

            &.has-dropdown {
                &:hover {
                    background: none;
                }
            }
        }

        &:hover {
            .main-menu__sub {
                display: block;
            }

            a.has-dropdown {
                color: #72d4cf;
                @include breakpoint(medium down) {
                    color: #2181c4;
                }
            }
        }
    }

    &__sub {

        & > li {
            position: relative;
            z-index: 1;
            & > a {
                display: inline-block;

                &.has-external {
                    padding-right: rem(23px);
                    background: url($baseUrl + 'arrow.svg') 100% 70% / 16px auto no-repeat;
                }
            }
        }

        @include breakpoint(large) {
            width: rem(289px);
            min-height: rem(319px);
            padding-top: rem(100px);
            padding-bottom: rem(50px);
            background: url($baseUrl + 'bg-menu-dropdown-top.png') 0 0 / contain no-repeat;
            transform: translateX(-50%);
            z-index: 3;
            display: none;
        	filter: drop-shadow(0 rem(9px) rem(49px) rgba(66, 158, 223, 0.76));
            @include abs (rem(-7px),50%,auto,auto);

            @include before {
                height: rem(179px);
                background: url($baseUrl + 'bg-menu-dropdown-bottom.png') 0 0 / contain no-repeat;
                @include abs (auto);
            }

            @include after {
                background: url($baseUrl + 'px-menu-dropdown.png') 0 0 / contain repeat-y;
                @include abs (rem(139px),0,0,rem(179px));
            }

            & > li {
                padding: rem(15px) rem(25px) 0;
                @include fs (rem(25px), 1.1, $exo-l);
                a {
                    @include link-color (#fff,#72d4cf);
                }
            }
        }

        @include breakpoint(medium down) {
            & > li {
                padding: rem(25px) rem(15px) 0;

                a {
                    @include link-color (#aaa,#2181c4);
                }
            }
        }
    }

    &__dropdown {
        width: rem(36px);
        height: rem(36px);
        display: block;
        border-bottom: 2px solid #333;
        cursor: pointer;
        position: relative;
        z-index: 102;
        @include abs (0,auto,rem(15px),auto);
        // background-color: coral;
        @include before {
            border-top: 2px solid #333;
            @include abs (auto,0,0,rem(5px));
        }
        @include after {
            border-top: 2px solid #333;
            @include abs (auto,rem(12px),0,rem(12px));
        }

        &.open {
            border: 0;
            position: fixed;
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
                @include abs (auto,0,0,rem(5px));
            }
        }
    }

    @include mq (tablet-wide) {
        padding-top: rem(7px);
        & > li {
            padding: 0 rem(1px);
            @include fs (rem(22px), rem(56px), $exo-l);
            & > a {
                min-width: rem(110px);
                padding: rem(10px);
                display: inline-block;
                text-align: center;
                @include link-color (#333,#76d9d1);
                &:hover {
                	background: url($baseUrl + 'menu-hover-shape.svg') 50% 50% / 100% auto no-repeat;
                }
            }
        }
    }

    @include breakpoint(medium down) {
        padding: rem(58px) 0 rem(80px);
        background-color: #f5fafe;
        z-index: 100;
        display: none;
        box-shadow: 0 5px 10px #a7adb1;
        @include abs (0,0,0,auto);

        &.open {
            position: fixed;
        }
        & > li {
            padding: rem(25px) rem(15px) 0;
            display: block;
        }
    }
}

@media screen and (max-width: 1500px) {
    .main-menu {
        padding-left: 7%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .main-menu {
        // padding-left: 17%;
    }
}
