html, body {
	overflow-x: hidden;
	min-height: 100%;
}

html {
	font-size: $html-font-size;
	-webkit-text-size-adjust: none;
}

body {
	display: flex;
	flex-direction: column;
	min-width: 320px;
	min-height: 100vh;
	color: $main-text-color;
	background-color: #fff;
	@include fs (rem(24px), rem(35px), $muli-r);
}

::selection,
::-moz-selection {
	color: #333;
	background-color: #21bbee;
}

.section {
	position: relative;
}

.main-content {
	flex: 1 auto;
	// overflow-x: hidden;
}


