// .a {
// 	&--inherit { color: inherit; }
// }

.link {
	position: relative;
	display: inline-block;
	color: $c--persian-green;
	@include font(mukta, 15px, 24px);
	transition: .2s;
	&::before {
		@include pseudo;
		bottom: 0;
		left: 0;
		width: 0;
		height: 2px;
		background-color: $c--persian-green;
		transition: .2s;
	}

    // mouse over link
    &:hover,
	&:focus {
        // color: #00cabc;
        color: lighten($c--persian-green, 10%);
		&::before {
			width: 100%;
		}
    }

    // // selected link
	&:active {
		color: $c--persian-green;
		&::before {
			width: 0;
		}
	}
	&--underline {
		&::before {
			width: 100%;
		}
		&:hover {
			&::before {
				width: 0;
			}
		}
	}
	&--empty {
		&::before {
			display: none;
		}
	}
}


.link-secondary {
	position: relative;
	display: inline-block;
	color: $c--dark-gray;
	@include font(mukta, 15px, 24px);
	transition: .2s;
	&::before {
		@include pseudo;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: $c--dark-gray;
		transition: .2s;
	}
	&:hover,
	&:focus {
		color: lighten($c--dark-gray, 10%);
		&::before {
			opacity: 0;
		}
	}
	&:active {
		color: $c--dark-gray;
		&::before {
			opacity: 1;
		}
	}
}

.link-tertiary {
	position: relative;
	display: inline-block;
	color: $c--dark-gray;
	text-transform: uppercase;
	@include font(mukta-medium, 18px, 26px);
	@include mq(desktop) {
		@include font(mukta, 40px, 40px);
	};
	&:hover {
		&::before {
			// transform: translate(calc(100% + 40%), 0%);
			left: calc(100% - 24px);
			@include mq(desktop) {
				left: calc(100% - 50px);
			};
		}
	}
	// transition: .2s;
	&::before {
		@include pseudo;
		top: 42%;
		left: -24px;
		width: 50px;
		height: 1px;
		pointer-events: none;
		background-color: $c--dark-gray;
		// transform: translate(-40%, 0%);
		transition: .35s $ease--out-cubic;
		@include mq(desktop) {
			width: 100px;
			height: 3px;
			left: -50px;
		};
	}
}
