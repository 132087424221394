.page-404 {
    padding-bottom: rem(50px);
    text-align: center;

    &__box {
        min-height: rem(695px);
        padding: rem(50px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column wrap;
        color: #fff;
        background: url($baseUrl + 'shapes/404-shape.svg') 50% 50% / contain no-repeat;
    }

    &__title-img {
        max-width: 50%;
    }
    
    &__title {
        padding-top: rem(21px);
        color: #fff;
    	@include fs (rem(40px), 1.1, $exo-b);
    }

    &__go-home {
        padding-top: rem(53px);
    }

    @include breakpoint(medium only) {
        &__box {
            min-height: rem(500px);
        }
    }
    @include breakpoint(small only) {
        &__box {
            min-height: rem(300px);
        }
        &__title-img {
            max-width: rem(158px);
        }
        &__title {
            font-size: rem(19px);
        }
        &__go-home {
            padding-top: rem(23px);
        }
    }
}
