/*
<div class="checkbox">
    <input type="checkbox" id="cb" name="cb" />
    <label for="cb"></label>
    <label for="cb" class="checkbox__text">Text</label>
</div>
*/

// Create animation called 'check'
@keyframes check {
    0% { height: 0; width: 0; }
    25% { height: 0; width: 40%; }
    50% { height: 70%; width: 40%; }
}

// Create the backgrounds for the checkboxes
.checkbox {
    display: flex;
    flex-flow: row nowrap;
    &__text {

        cursor: pointer;
        color: $c--dark-gray;
        @include font(mukta, 16px, 18px);
    }
    // This label functions as actual clickable area, because the default checkbox is rubbish
    label:not([class]) {
        position: relative;
        flex: 0 0 auto;
        margin-right: 8px;
        height: 19px;
        width: 19px;
        border: 2px solid $input-border-color;
        border-radius: 1px;
        cursor: pointer;
        background-color: $c--white;
        transition: border-color .2s;
        @include mq(desktop) {
            margin-right: 14px;
        };

        // This is the pseudo-element for the check mark
        &::after {

            // The borders
            border-right: 2px solid transparent;
            border-top: 2px solid transparent;

            // Positioning and stuff
            content: '';
            display: block;
            position: absolute;
            top: 8px;
            left: 2px;
            // right: 0;
            width: 40%;
            height: 70%;

            /*
            This element has a border on the right, and top. By rotating it looks like a check symbol, but the wrong way. That's why I'm flipping it with scaleX(-1)
            */
            transform: scaleX(-1) rotate(180deg + -45deg);

            /*
            I want to position the symbol using the left top, because when increasing the width and height of an object, it expands from the top left corner as well. That is what makes it looks like it's being drawn.
            */
            transform-origin: top left;
        }
    }

    // Hide the input!
    input { display: none; }

    // When the input is checked (by clicking the label), I wan't the animation to run and the colour to change.
    input {
        &:not(:checked) + label {
            &:hover {
                border-color: $c--persian-green;
            }
        }
        &:checked + label::after {
            animation: check 0.3s;
            border-color: $c--persian-green;
        }
    }

    // Options for the alternative checkbox
    &.checkbox-alt {
        // The alternative checkbox must animate infinitely when I hover
        label:hover:after {
            animation: check 0.6s ease infinite;
        }

        // When it's checked, dont bother animating
        input:checked + label:after {
            animation: none;
        }
    }
}
