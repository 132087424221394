.form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: $c--white;
    box-shadow: 0 8px 29px rgba(0, 0, 0, 0.03);
    @include mq(tablet) {
        margin: 0;
    };
    &__title {
        margin-bottom: 24px;
    }
    &__field {
        & + & {
            margin-top: 26px;
            @include mq(desktop) {
                margin-top: 34px;
            };
        }
    }

}
.form-field {
    position: relative;

    &__head {
        margin-bottom: 6px;
    }
    &__label {
        width: 100%;
        @include font(mukta, 23px, 33px);
        color: $c--dark-gray;
        &--textarea {
            text-transform: uppercase;
            color: $c--medium-gray;
            @include font(mukta, 14px, 16px);
        }
    }
    &__label-secondary {
        width: 100%;
        color: $c--dark-gray;
        text-transform: uppercase;
        @include font(mukta-medium, 14px, 20px);
    }
    &__input {
        width: 100%;
        transition: width .15s;
        &--icon {
            width: calc(100% - 40px);
            margin-left: 40px;
            border-radius: 0px $global-radius $global-radius 0px !important;
        }
    }
    &__select {
        width: 100%;
    }
    &__textarea {
        width: 100%;
        resize: vertical;
        box-sizing: border-box;
    }
    &__icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 100%;
        padding: 8px;
        border: 1px solid #d7d7d7;
        border-right: none;
        svg {
            fill: blue;
            height: 100%;
        }
    }
    &__wrapper {
        position: relative;
    }
    &__error {
        display: none;
        margin-top: 6px;
        color: $c--fire;
        @include font(hel-light, 14px);
        &:empty {
            display: none;
        }
    }
    &__valid {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        border: 1px solid #d7d7d7;
        border-left: none;
        transform: scale3d(0,1,1);
        transform-origin: center right;
        transition: transform .15s;
        span {
            display: inline-block;
            vertical-align: middle;
            width: 40px;
            height: 100%;
            transition: border-color .2s;

            &::after {
                // The borders
                border-right: 2px solid transparent;
                border-top: 2px solid transparent;

                // Positioning and stuff
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 14px;
                // right: 0;
                width: 18%;
                height: 36%;

                transform: scaleX(-1) rotate(180deg + -45deg);

                transform-origin: top left;
                transition: border-color .15s .3s;
            }
        }
    }
    &__half {
        & + & {
            margin-top: 34px;
            @include mq(desktop) {
                margin-top: 0;
            };
        }
    }
    &__desc {
        color: $c--dark-gray;
        @include font(mukta, 16px, 28px);
    }
    &__tip {
        margin-top: 15px;
    }
    &__radio {
        display: block;
        padding: 25px 20px;
        border: 1px solid $c--dark-gray;
        cursor: pointer;
        & + & {
            margin-top: 10px;
        }

        .md-input {
            display: none;
            margin-top: 20px;
        }
    }
}

.form-field-half {
    @include mq(desktop) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: flex-end;
    };
    & > * {
        width: 100%;
        @include mq(desktop) {
            flex: 0 1 48%;
        };
    }
}

.has-error {}
.has-valid {}

@keyframes check-valid {
    0% { height: 0; width: 0; }
    25% { height: 0; width: 18%; }
    50% { height: 36%; width: 18%; }
}
