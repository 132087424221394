// Contact
.page-contact {
    padding-bottom: rem(100px);

    @include mq (desktop) {
        padding-bottom: rem(200px);
    }

    @include breakpoint(medium down) {
        @include fs (rem(18px), rem(28px), $muli-r);
    }
}

// Contact Box
.contact-box {

    &__title {
        padding-top: rem(76px);
        @include fs (rem(93px), 1.1, $exo-b);
    }

    &__text {
        padding: rem(25px) 0 rem(15px);
        font-family: $muli-b;
    }

    // Contact items
    &__item {
        padding: 0 0 rem(10px) rem(50px);
        margin-top: rem(20px);
        background-position: 0 3px;
        background-repeat: no-repeat;
        background-size: 39px auto;
        @include fs (rem(30px), 1.1, $exo-b);
        a {
            @include link-color ($main-link-color,$dark-blue);
        }

        &.phone {
		    background-image: url($baseUrl + 'icons/phone.svg');
        }
        &.email {
            background-image: url($baseUrl + 'icons/email.svg');
            background-position: 0 9px;
        }
        &.map {
		    background-image: url($baseUrl + 'icons/map.svg');
        }
    }

    // Social Menu
    &__social {
        padding-top: rem(38px);
        display: flex;
        flex-flow: row wrap;
        li {
            padding-right: rem(17px);
        }

        a[class^='icon-'] {
            width: rem(87px);
            height: rem(87px);
            display: inline-block;
            transition: .2s ease-in-out;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;
            &:hover {
                opacity: 0.7;
            }

            &[class='icon-facebook'] {
		        background-image: url($baseUrl + 'social/facebook.svg');
            }
            &[class='icon-instagram'] {
		        background-image: url($baseUrl + 'social/instagram.svg');
            }
            &[class='icon-twitter'] {
		        background-image: url($baseUrl + 'social/twitter.svg');
            }
            &[class='icon-linkedin'] {
		        background-image: url($baseUrl + 'social/linkedin.svg');
            }
        }

        @include breakpoint(small only) {
            a[class^='icon-'] {
                width: rem(64px);
                height: rem(64px);
            }
        }
    }

    // Form
    &__form {
        padding: rem(85px) rem(58px);
        border-radius: rem(47px);
        background-color: #f2f9fe;

        .title {
            padding-bottom: rem(10px);
            @include fs (rem(55px), 1.1, $exo-b);
        }

        label {
            padding: rem(17px) 0 rem(6px) rem(20px);
            display: block;
            cursor: pointer;
            @include fs (rem(25px), 1.1, $exo-b);
        }

        .field {
            padding-top: rem(10px);

            input[type='text'],
            input[id^='form-'],
            textarea {
                width: 100%;
                padding: rem(10px) rem(20px);
                border-radius: rem(23px);
                border: 0;
                box-shadow: 0 5px 20px #c9e1f0;
                @include fs (rem(25px), rem(34px), $exo-b);

                &:focus {
                    padding: rem(9px) rem(19px);
                    border: 1px solid #22baee;
                }

                &.error-form {
                    padding: rem(9px) rem(19px);
                    border: 1px solid $red-error;

                    & + .error-text {
                        display: block;
                    }
                }
            }
            textarea {
                height: rem(115px);
                resize: vertical;
            }
        }

        .error-text {
            padding: rem(7px) rem(25px) 0;
            display: none;
            color: $red-error;
        	@include fs (rem(16px), 1.1, $muli-r);
        }

        .submit {
            padding-top: rem(30px);
            text-align: center;
        }

        @include breakpoint(small only) {
            padding: rem(30px) rem(15px);
            border-radius: rem(38px);

            .title {
                padding: 0 rem(20px);
                font-size: rem(30px);
            }
        }
    }

    @include breakpoint(medium down) {
        &__form {
            margin-top: rem(65px);
        }
    }

    @include breakpoint(small only) {
        &__title {
            padding-top: 0;
            font-size: rem(30px);
        }

        &__text {
            padding-top: rem(15px);
        }

        &__item {
            @include fs (rem(20px), 1.7, $exo-b);
        }

        &__social {
            padding-top: rem(20px);
            justify-content: space-around;
            li {
                padding-right: 0;
            }
        }
    }
}
