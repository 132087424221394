// Normalize Styles
// @import "node_modules/normalize.css/normalize";

/* HELPERS */
@import "helpers/vars",
        "helpers/functions",
        "helpers/mixins",
        "helpers/helpers";

/* BASE */
@import "base/reset",
        "base/typography";

/* LAYOUT */
@import "layout/base";
@import "node_modules/foundation-sites/scss/components/flex";
@import "node_modules/foundation-sites/scss/foundation";
@include foundation-xy-grid-classes(
    $base-grid: true,
    $margin-grid: true,
    $padding-grid: true,
    $block-grid: true,
    $collapse: true,
    $offset: true,
    $vertical-grid: true,
    $frame-grid: true
);
@include foundation-flex-classes;
@include foundation-visibility-classes;

/* VENDORS */
@import
        "vendors/hamburgers/settings",
        "node_modules/hamburgers/_sass/hamburgers/hamburgers",
        "node_modules/selectric/src/selectric",
        // "vendors/selectric/custom",
        // "vendors/tooltip.js/custom",
        // "node_modules/tippy.js/dist/tippy"
        "vendors/sweetalert2/custom",
        "node_modules/slick-carousel/slick/slick.scss",
        "vendors/slick-carousel/custom-theme.scss";

// MODULES
@import "../_modules/form/form",
        "../_modules/select/select",
        "../_modules/header/header",
        "../_modules/header/dropdown",
        "../_modules/hamburger/hamburger",
        "../_modules/footer/footer";

// COMPONENTS
@import "components/alert-message",
        "components/object-fit-polyfill",
        "components/icon",
        "components/icons",
        "components/button",
        "components/link",
        "components/radio",
        "components/select",
        "components/input",
        "components/textarea",
        "components/checkbox",
        "components/subscribe-input",
        "components/card",
        "components/socials",
        "components/horizontal-rule",
        "components/list",
        "components/badge",
        "components/custom-upload",
        "components/avatar",
        "components/breadcrumbs",
        "components/spinner",
        "components/dropdown",
        "components/pagination",
        "components/keyframes";

/* PAGES */
@import "pages/general",
        "pages/home/index",
        "pages/projects/index",
        "pages/services/index",
        "pages/contact/index",
        "pages/404";
