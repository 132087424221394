// Index Page
.page-home {
    padding-bottom: rem(100px);

    @include mq (desktop) {
        padding-bottom: rem(200px);
    }

    &__title-text {
        text-align: center;
        @include fs (rem(18px), rem(22px), $muli-b);
    }

    @include breakpoint(medium down) {
        @include fs (rem(18px), rem(28px), $muli-r);
    }
}


// Digital Box
.digital-box {
    padding-top: rem(100px);
    padding-bottom: rem(30px);
    position: relative;
    @include clearfix;

    &__title {
        @include fs (rem(100px), 1.2, $exo-b);
    }

    &__text {
        padding-top: rem(35px);
        font-family: $muli-b;
    }

    &__more {
        padding-top: rem(58px);
    }

    @media screen and (max-width: 1500px) {
        padding-top: rem(30px);

        &__title {
            font-size: rem(70px);
        }

        &__more {
            padding-top: rem(28px);
        }
    }

    @include breakpoint(medium down) {
        @include fs (rem(18px), rem(28px), $muli-r);

        &__title {
            font-size: rem(50px);
        }
    }

    @include breakpoint(small only) {
        padding-top: 87%;

        &__title {
            font-size: rem(30px);
        }
    }
}

// Innovation Box
.innovation-box {
    min-height: rem(380px);
    position: relative;
    @include clearfix;

    &__image {
        max-width: 39%;
        margin-top: rem(-30px);
    }

    &__wrap {
        @include abs;

        & > .grid-container {
            height: 100%;
        }
        .custom-row {
            height: 100%;
        }
        .custom-cell {
            display: flex;
            flex-flow: column wrap;
            // align-items: center;
            justify-content: center;
        }
    }

    &__title {
        @include fs (rem(70px), 1.2, $exo-b);
    }

    &__text {
        padding-top: rem(30px);
        font-family: $muli-b;
    }

    @include breakpoint(medium down) {
        @include fs (rem(18px), rem(28px), $muli-r);

        &__title {
            font-size: rem(35px);
        }
    }

    @include breakpoint(small only) {
        &__image {
            max-width: 100%;
            margin-top: 0;

            img {
                max-width: 90%;
                display: block;
            }
        }

        &__wrap {
            position: static;
        }

        &__title {
            padding-top: rem(20px);
        }
    }
}


// Services Slide Menu
@keyframes services-hover {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}

.services-menu {
    margin-top: rem(105px);
    display: flex;
    flex-flow: row wrap;
    position: relative;

    a[class^='menu-'] {
        height: 50%;
        flex: 0 0 50%;
        padding: rem(30px);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        @include link-color (#fff,#76d9d1);
        @include fs (rem(50px), rem(61px), $exo-l);
        @include before {
            display: none;
            animation: services-hover .5s ease-in-out forwards;
            @include abs;
            background: url($baseUrl + 'bg-services-menu.png') center / cover no-repeat;
        }

        &:hover {
            &:before {
                display: block;
            }
        }

        &.menu-1 {
            background: url($baseUrl + 'services-square-1.svg') center no-repeat;
        }
        &.menu-2 {
            background: url($baseUrl + 'services-square-2.svg') center no-repeat;
        }
        &.menu-3 {
            background: url($baseUrl + 'services-square-3.svg') center no-repeat;
        }
        &.menu-4 {
            background: url($baseUrl + 'services-square-4.svg') center no-repeat;
        }
    }

    @include before {
        width: rem(145px);
        height: rem(145px);
        background: url($baseUrl + 'plus.svg') 50% 50% / contain no-repeat;
        transform: translate(-50%,-50%);
        z-index: 1;
        @include abs (50%,50%,auto,auto);
    }
    @include breakpoint(medium down) {
        a[class^='menu-'] {
            @include fs (rem(38px), 1.2, $exo-l);
        }
    }
    @include breakpoint(small only) {
        a[class^='menu-'] {
            height: auto;
            min-height: rem(120px);
            flex: 1 0 100%;
            font-size: rem(30px);
            background-size: 107% auto !important;
        }

        &:before {
            display: none;
        }
    }

    @include mq (tablet-small) {
        height: rem(360px);
    }
    @include mq (tablet) {
        height: rem(480px);
    }
    @include mq (tablet-wide) {
        height: rem(570px);
    }
    @include mq (desktop) {
        height: rem(676px);
    }
    @include mq (desktop-wide) {
        height: rem(760px);
    }
    @include mq (desktop-fhd) {
        height: rem(860px);
    }

    @media screen and (min-width: 640px) and (max-width: 1800px) {
        a[class^='menu-'] {
            background-size: cover !important;
        }
    }
}

// Slider Team
.slider-team {
    padding: rem(90px) rem(50px) 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    @include mq(desktop) {
        padding: rem(90px) rem(70px) 0;
    }

    &__slide {
        flex: 0 0 32%;
        padding: 0 rem(10px);
        text-align: center;
    }

    &__image {
        img {
            width: 100%;
            height: 187px;
            margin: 0 auto;
            @include mq(tablet) {
                height: 285px;
            };
        }
        &-shape4 {
            max-width: 182px;
            clip-path: url('#shape4-mobile');
            // clip-path: url($baseUrl + 'svg/shapes.svg#shape4-mobile');
            @include mq(tablet) {
                max-width: 294px;
                clip-path: url('#shape4');
                // clip-path: url($baseUrl + 'svg/shapes.svg#shape4');
            };
        }
        &-shape5 {
            max-width: 199px;
            clip-path: url('#shape5-mobile');
            // clip-path: url($baseUrl + 'svg/shapes.svg#shape5-mobile');
            @include mq(tablet) {
                max-width: 304px;
                clip-path: url('#shape5');
                // clip-path: url($baseUrl + 'svg/shapes.svg#shape5');
            };
        }
        &-shape6 {
            max-width: 191px;
            clip-path: url('#shape6-mobile');
            // clip-path: url($baseUrl + 'svg/shapes.svg#shape6-mobile');
            @include mq(tablet) {
                max-width: 280px;
                clip-path: url('#shape6');
                // clip-path: url($baseUrl + 'svg/shapes.svg#shape6');
            };
        }
    }

    &__name {
        padding-top: rem(32px);
        @include fs (rem(30px), 1.1, $exo-b);
    }

    &__position {
        padding-top: rem(7px);
        @include fs (rem(18px), 1.1, $muli-b);
    }

    @include breakpoint(small only) {
        display: block;

        &__slide {
            flex: 0 0 100%;
            padding: 0;
        }

        &__position {
            padding-bottom: rem(30px);
        }
    }
}
