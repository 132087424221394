.b-avatar {
    // text-align: center;
    display: inline-block;
    img {
        display: block;
        margin: 0 auto;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        @include mq(tablet) {
            width: 50px;
            height: 50px;
        };
    }
}
