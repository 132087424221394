// @font-face {
// 	font-family: 'HelveticaNeueCyr';
// 	src: local('HelveticaNeueCyr'), local('HelveticaNeueCyr Regular'),
// 		 url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Light.woff2') format('woff2'),
// 		 url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Light.woff') format('woff'),
// 		 url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Light.ttf') format('truetype');
// 	font-weight: 300;
// 	font-style: normal;
// }
//
@font-face {
  font-family: 'Exo2-Thin';
  src: url('../fonts/Exo2/Exo2-Thin.ttf') format('truetype'),
       url('../fonts/Exo2/Exo2-Thin.woff') format('woff'),
       url('../fonts/Exo2/Exo2-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2-Light';
  src: url('../fonts/Exo2/Exo2-Light.ttf') format('truetype'),
       url('../fonts/Exo2/Exo2-Light.woff') format('woff'),
       url('../fonts/Exo2/Exo2-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2-SemiBold';
  src: url('../fonts/Exo2/Exo2-SemiBold.ttf') format('truetype'),
       url('../fonts/Exo2/Exo2-SemiBold.woff') format('woff'),
       url('../fonts/Exo2/Exo2-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Muli-Regular';
  src: url('../fonts/Muli/Muli-Regular.ttf') format('truetype'),
       url('../fonts/Muli/Muli-Regular.woff') format('woff'),
	   	 url('../fonts/Muli/Muli-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Muli-SemiBold';
  src: url('../fonts/Muli/Muli-SemiBold.ttf') format('truetype'),
       url('../fonts/Muli/Muli-SemiBold.woff') format('woff'),
       url('../fonts/Muli/Muli-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

p {
	padding: 21px 0 0;
	margin: 0;
}

strong { font-weight: 700; }

.heading {
    color: $c--soft-black;
	// text-transform: uppercase;
	@include font(sarabun, 30px, 40px);
	@include mq(tablet) {
		@include font(sarabun, 60px, 70px);
	};
	&--center {
		text-align: center;
	}
}
.heading-2 {
	text-align: center;
	color: $c--soft-black;
	@include font(sarabun, 30px, 36px);
	@include mq(desktop) {
		@include font(sarabun, 50px, 60px);
	};
	&--left {
		text-align: left;
	}
}
.heading-3 {
	text-align: center;
	color: $c--soft-black;
	@include font(sarabun, 30px, 36px);
	@include mq(desktop) {
		@include font(sarabun, 40px, 50px);
	};

	&--left {
		text-align: left;
	}
}
.heading-4 {
	text-align: center;
	color: $c--soft-black;
	@include font(sarabun, 30px, 36px);
	text-transform: uppercase;
	@include mq(desktop) {
		@include font(sarabun, 35px, 50px);
	};

	&--left {
		text-align: left;
	}
}
.heading-5 {
	text-align: center;
	color: $c--soft-black;
	@include font(sarabun, 30px, 50px);
	&--left {
		text-align: left;
	}
}
.heading-6 {
	text-align: center;
	color: $c--soft-black;
	@include font(sarabun, 23px, 33px);
	@include mq(desktop) {
		@include font(sarabun, 23px, 33px);
	};

	&--left {
		text-align: left;
	}
}
