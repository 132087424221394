.icon-hamburger {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 26px;
    span {
        @include center(both);
        display: block;
        width: 60%;
        height: 2px;
        background-color: #d7d7d7;
        border-radius: 2px;
        &::before, &::after {
            @include pseudo;
            width: 100%;
            height: 2px;
            border-radius: inherit;
            background-color: inherit;
        }
        &::before {
            top: -4px;
        }
        &::after {
            bottom: -4px;
        }
    }
}

.icon-kebab {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // width: 4px;
    // height: 4px;
    width: 16px;
    height: 100%;
    cursor: pointer;

    span {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 4px;
        height: 4px;
        background-color: #86acff;
        border-radius: 50%;
        &::before, &::after {
            @include pseudo(inline-block);
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: inherit;
            border-radius: inherit;
        }
        &::before {
            top: -6px;
        }

        &::after {
            top: 6px;
        }
    }
}

.icon-info {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    // width: 20px;
    // height: 20px;
    border-radius: 50%;
    border: 2px solid $c--medium-gray;
    color: $c--medium-gray;
    transform: rotateX(180deg);
    &--green {
        border-color: $c--persian-green;
        color: $c--persian-green;
    }
    span {
        display: inline-block;
        @include center(both);
        line-height: 11px;
    }
}

.icon-file-wrap {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    &::before {
        @include pseudo(block, absolute, attr(data-ext));
        bottom: 0;
        left: 0;
        right: 0;
        @include truncate(100%);
        color: $c--black;
        text-align: center;
        text-transform: uppercase;
        @include font(mukta-bold, 10px, 16px);
        @include mq(desktop) {
            @include font(mukta-bold, 14px, 20px);
        };
    }
}
