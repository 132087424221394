// <div class="img-container">
//     <img src=">
// </div>

.object-fit-cover {
	position: relative;
	background-size: cover;
	background-position: center center;
	img {
		opacity: 0;
	}
}
