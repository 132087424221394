.lds-roller {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    // width: 64px;
    // height: 64px;
    width: 34px;
    height: 34px;
}
.lds-roller div {
    animation: lds-roller 1.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    // transform-origin: 32px 32px;
    transform-origin: 17px 17px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: $c--dark-gray;
    margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
    &::after {
        // top: 50px;
        // left: 50px;
        top: 25px;
        left: 25px;
    }
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
    &::after {
        // top: 54px;
        // left: 45px;
        top: 27px;
        left: 23px;
    }
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
    &::after {
        top: 29px;
        left: 20px;
    }
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
    &::after {
        top: 29px;
        left: 16px;
    }
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
    &::after {
        top: 29px;
        left: 13px;
    }
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
    &::after {
        top: 27px;
        left: 10px;
    }
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
    &::after {
        top: 25px;
        left: 7px;
    }
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
    &::after {
        top: 23px;
        left: 5px;
    }
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
