.page-footer {
    min-height: rem(300px);
    padding-top: rem(110px);
    padding-bottom: rem(30px);
    background: url($baseUrl + 'footer-bg.svg') 50% 100% / cover no-repeat;
    color: #fff;
	@include font($muli-r, 17px, 22px);

    &__title {
        color: #76d9d1;
    	@include font($muli-b, 18px, 22px);
    }

    p.text {
        padding-top: 23px;
    }

    @include breakpoint(small only) {
        &__title {
            padding-top: rem(34px);
        }
    }
}

.footer-logo {
    width: rem(300px);

    @include breakpoint(medium down) {
        width: 100%;
        max-width: 250px;
    }
}
