/*
Inline list
```
<ul class="inline-children--block">
	<li>item 1</li>
	<li>item 2</li>
	<li>item 3</li>
	<li>item 4</li>
	<li>item 5</li>
</ul>
```
*/

.ol,
.ul {
	margin-left: $spacing-unit;
	margin-right: $spacing-unit;

	> li {
		margin-top: $rel-spacing-unit--small;

		&:first-child {
			margin-top: 0;
		}
	}
}

.ol {
	list-style-type: decimal;
}

.ul {
	list-style-type: disc;
}
