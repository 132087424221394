@mixin clearfix {
	&:before {
		content: '';
		display: table;
	}
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

$bps: (
    "phone":        320px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "tablet-wide":  1024px,
    "desktop":      1200px,
    "desktop-wide": 1440px,
    "desktop-fhd": 	1920px
);
@mixin mq($width, $type: min) {
    @if map_has_key($bps, $width) {
        $width: map_get($bps, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

@mixin font($name, $size: '', $line-height: $size) {

	@if $size != '' {
		@if $name == sarabun {
			font: 400 rem($size)#{"/"} lh($size, $line-height) "Sarabun", sans-serif;
		} @else if $name == sarabun-semi-bold {
			font: 600 rem($size)#{"/"} lh($size, $line-height) "Sarabun", sans-serif;
		} @else if $name == mukta {
			font: 400 rem($size)#{"/"} lh($size, $line-height) "Mukta", sans-serif;
		} @else if $name == mukta-medium {
			font: 500 rem($size)#{"/"} lh($size, $line-height) "Mukta", sans-serif;
		} @else if $name == mukta-bold {
			font: 700 rem($size)#{"/"} lh($size, $line-height) "Mukta", sans-serif;
		} @else {
			font: 400 rem($size)#{"/"} lh($size, $line-height) $name;
		}

	} @else {
		@if $name == sarabun {
			font-family: "Sarabun", sans-serif;
		} @else if $name == mukta {
			font-family: "Mukta", sans-serif;
		}
	}

}

@mixin fs ($custom-size: 24px, $custom-line-height: 35px, $custom-family: $muli-r, $custom-weight: normal) {
	font: {
		family: $custom-family;
		size: $custom-size;
		weight: $custom-weight;
	}
	line-height: $custom-line-height;
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

@mixin triangle($direction, $color, $size: 6px, $position: absolute, $round: false) {
    @include pseudo($pos: $position);
    width: 0;
    height: 0;
    @if $round {
        border-radius: 2px;
    }
    @if $direction == down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round( $size / 2.5 );
    } @else if $direction == up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round( $size / 2.5 );
    } @else if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    } @else if $direction == left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}

@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
	overflow: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
}

// Define vertical, horizontal, or both position
@mixin center($position) {
	position: absolute;

	@if $position == 'vertical' {
		top: 50%;
		transform: translateY(-50%);
	}
	@else if $position == 'horizontal' {
		left: 50%;
		transform: translate(-50%);
	}
	@else if $position == 'both' {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@mixin background-gradient($start-color, $end-color, $orientation) {
	background: $start-color;

	@if $orientation == 'vertical' {
		background: linear-gradient(to bottom, $start-color, $end-color);
	} @else if $orientation == 'horizontal' {
		background: linear-gradient(to right, $start-color, $end-color);
	} @else {
		background: radial-gradient(ellipse at center, $start-color, $end-color);
	}
}

// Updated
@mixin link-decoration ($decoration) {
	@if $decoration == nu {
		text-decoration: none;
		&:hover { text-decoration: underline; }
	}
	@else if $decoration == un {
		text-decoration: underline;
		&:hover { text-decoration: none; }
	}
	@else if $decoration == nn {
		text-decoration: none;
		&:hover { text-decoration: none; }
	}
	@else {
		text-decoration: underline;
		&:hover { text-decoration: underline; }
	}
}

@mixin link-color ($custom-color: $main-link-color, $custom-color-hover: $main-link-color-hover) {
	color: $custom-color;
	&:hover { color: $custom-color-hover; }
}

@mixin abs ($custom-top: 0, $custom-left: 0, $custom-right: 0, $custom-bottom: 0, $custom-position: absolute) {
	top: $custom-top;
	left: $custom-left;
	right: $custom-right;
	bottom: $custom-bottom;
	position: $custom-position;
}

@mixin before {
	&:before {
		content: "";
		display: inline-block;
		@content;
	}
}
@mixin after {
	&:after {
		content: "";
		display: inline-block;
		@content;
	}
}
