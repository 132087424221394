// data-tables styles
.dropdown-trigger {
    position: relative;
    &:hover {
        & + .dropdown-content {
            display: block;
            opacity: 1;
            visibility: visible;
            transition: .3s;
            &--no-anim {
                transition: none;
            }
        }
    }
    &__arrow {
        width: 10px;
        height: 10px;
        margin-left: 10px;
        fill: $c--black;
    }
    &--pointer {
        cursor: pointer;
    }
}
.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    min-width: 130px;
    background-color: $c--white;
    border: 1px dashed $c--dark-gray;
    text-align: left;
    @include font(hel-medium, 15px);
    color: lighten($c--black, 10%);
    opacity: 0;
    visibility: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    &--top {
        bottom: 100%;
        top: auto;
    }
    &--right {
        right: 0;
        left: auto;
    }
    &--overlap {
        top: 0;
    }
    &--no-anim {
        display: none;
    }

    li {
        & + li {
            border-top: 1px solid #ededed;
        }
        a {
            position: relative;
            display: block;
            padding: 10px 18px;
            text-decoration: none;
            color: lighten($c--black, 10%);
            transition: .3s;
            &:hover,
            &.is-clicked {
                color: blue;
            }
            &.is-loading {
                .lds-roller {
                    display: inline-block;
                }
            }
            .lds-roller {
                right: 0;
                left: auto;
                user-select: none;
            }
        }
    }

    .icon {
        margin-right: 10px;
    }
}
