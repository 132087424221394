html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, menu, nav,
output, ruby, section, summary, time, mark,
audio, video {
	margin: 0;
	padding: 0;
	border: 0;
    box-sizing: border-box;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

input {
	box-sizing: border-box;
	// border: none;
}
// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {
// 	-webkit-appearance: none;
// 	margin: 0;
// }
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.42;
	-webkit-font-smoothing: antialiased;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote::before, blockquote::after,
q::before, q::after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
img {
	object-fit: cover;
	display: block;
	max-width: 100%;
	height: auto;
}

a {
	text-decoration: none;
	&:link {
		-webkit-tap-highlight-color: rgba(#fff, 0);
	}

}

textarea {
	font-family: Arial;
}

:focus {
	outline: none;
}

* {
	&,
	&::before,
	&::after {
		box-sizing: border-box;
	}
}

svg {
	width: 100%;
	height: 100%;
}

strong, b {
	font-weight: 700;
}
