// Services
.page-services {
    padding-bottom: rem(100px);
    
    @include mq (desktop) {
        padding-bottom: rem(200px);
    }

    @include breakpoint(medium down) {
        @include fs (rem(18px), rem(28px), $muli-r);
    }
}