@charset "UTF-8";
/* HELPERS */
/*
	Typography
*/
/*
	Colours
*/
/*
	Layout
*/
/*
	Easing/Timing
*/
.circle {
  border-radius: 50%;
}

.t-right {
  text-align: right;
}

.t-center {
  text-align: center;
}

.help-block {
  margin-left: 20px;
  color: #ee6c0d;
  line-height: 20px;
  font-size: 1rem;
  font-family: "Muli-Regular";
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.3;
}

/* BASE */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, menu, nav,
output, ruby, section, summary, time, mark,
audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

input {
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1.42;
  -webkit-font-smoothing: antialiased;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  object-fit: cover;
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

a:link {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

textarea {
  font-family: Arial;
}

:focus {
  outline: none;
}

*, *::before, *::after {
  box-sizing: border-box;
}

svg {
  width: 100%;
  height: 100%;
}

strong, b {
  font-weight: 700;
}

@font-face {
  font-family: 'Exo2-Thin';
  src: url("../fonts/Exo2/Exo2-Thin.ttf") format("truetype"), url("../fonts/Exo2/Exo2-Thin.woff") format("woff"), url("../fonts/Exo2/Exo2-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Exo2-Light';
  src: url("../fonts/Exo2/Exo2-Light.ttf") format("truetype"), url("../fonts/Exo2/Exo2-Light.woff") format("woff"), url("../fonts/Exo2/Exo2-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Exo2-SemiBold';
  src: url("../fonts/Exo2/Exo2-SemiBold.ttf") format("truetype"), url("../fonts/Exo2/Exo2-SemiBold.woff") format("woff"), url("../fonts/Exo2/Exo2-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Muli-Regular';
  src: url("../fonts/Muli/Muli-Regular.ttf") format("truetype"), url("../fonts/Muli/Muli-Regular.woff") format("woff"), url("../fonts/Muli/Muli-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Muli-SemiBold';
  src: url("../fonts/Muli/Muli-SemiBold.ttf") format("truetype"), url("../fonts/Muli/Muli-SemiBold.woff") format("woff"), url("../fonts/Muli/Muli-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

p {
  padding: 21px 0 0;
  margin: 0;
}

strong {
  font-weight: 700;
}

.heading {
  color: #333;
  font: 400 1.875rem / 1.3333333333 "Sarabun", sans-serif;
}

@media only screen and (min-width: 768px) {
  .heading {
    font: 400 3.75rem / 1.1666666667 "Sarabun", sans-serif;
  }
}

.heading--center {
  text-align: center;
}

.heading-2 {
  text-align: center;
  color: #333;
  font: 400 1.875rem / 1.2 "Sarabun", sans-serif;
}

@media only screen and (min-width: 1200px) {
  .heading-2 {
    font: 400 3.125rem / 1.2 "Sarabun", sans-serif;
  }
}

.heading-2--left {
  text-align: left;
}

.heading-3 {
  text-align: center;
  color: #333;
  font: 400 1.875rem / 1.2 "Sarabun", sans-serif;
}

@media only screen and (min-width: 1200px) {
  .heading-3 {
    font: 400 2.5rem / 1.25 "Sarabun", sans-serif;
  }
}

.heading-3--left {
  text-align: left;
}

.heading-4 {
  text-align: center;
  color: #333;
  font: 400 1.875rem / 1.2 "Sarabun", sans-serif;
  text-transform: uppercase;
}

@media only screen and (min-width: 1200px) {
  .heading-4 {
    font: 400 2.1875rem / 1.4285714286 "Sarabun", sans-serif;
  }
}

.heading-4--left {
  text-align: left;
}

.heading-5 {
  text-align: center;
  color: #333;
  font: 400 1.875rem / 1.6666666667 "Sarabun", sans-serif;
}

.heading-5--left {
  text-align: left;
}

.heading-6 {
  text-align: center;
  color: #333;
  font: 400 1.4375rem / 1.4347826087 "Sarabun", sans-serif;
}

@media only screen and (min-width: 1200px) {
  .heading-6 {
    font: 400 1.4375rem / 1.4347826087 "Sarabun", sans-serif;
  }
}

.heading-6--left {
  text-align: left;
}

/* LAYOUT */
html, body {
  overflow-x: hidden;
  min-height: 100%;
}

html {
  font-size: 16px;
  -webkit-text-size-adjust: none;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100vh;
  color: #333;
  background-color: #fff;
  font-family: "Muli-Regular";
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 2.1875rem;
}

::selection,
::-moz-selection {
  color: #333;
  background-color: #21bbee;
}

.section {
  position: relative;
}

.main-content {
  flex: 1 auto;
}

/**
 * Foundation for Sites by ZURB
 * Version 6.5.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.grid-container.fluid {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-container.fluid {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.grid-container.full {
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.grid-x {
  display: flex;
  flex-flow: row wrap;
}

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%;
}

.cell.auto {
  flex: 1 1 0px;
}

.cell.shrink {
  flex: 0 0 auto;
}

.grid-x > .auto {
  width: auto;
}

.grid-x > .shrink {
  width: auto;
}

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto;
  }
}

.grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex: 0 0 auto;
}

.grid-x > .small-1 {
  width: 8.3333333333%;
}

.grid-x > .small-2 {
  width: 16.6666666667%;
}

.grid-x > .small-3 {
  width: 25%;
}

.grid-x > .small-4 {
  width: 33.3333333333%;
}

.grid-x > .small-5 {
  width: 41.6666666667%;
}

.grid-x > .small-6 {
  width: 50%;
}

.grid-x > .small-7 {
  width: 58.3333333333%;
}

.grid-x > .small-8 {
  width: 66.6666666667%;
}

.grid-x > .small-9 {
  width: 75%;
}

.grid-x > .small-10 {
  width: 83.3333333333%;
}

.grid-x > .small-11 {
  width: 91.6666666667%;
}

.grid-x > .small-12 {
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .medium-shrink, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex: 0 0 auto;
  }
  .grid-x > .medium-shrink {
    width: auto;
  }
  .grid-x > .medium-1 {
    width: 8.3333333333%;
  }
  .grid-x > .medium-2 {
    width: 16.6666666667%;
  }
  .grid-x > .medium-3 {
    width: 25%;
  }
  .grid-x > .medium-4 {
    width: 33.3333333333%;
  }
  .grid-x > .medium-5 {
    width: 41.6666666667%;
  }
  .grid-x > .medium-6 {
    width: 50%;
  }
  .grid-x > .medium-7 {
    width: 58.3333333333%;
  }
  .grid-x > .medium-8 {
    width: 66.6666666667%;
  }
  .grid-x > .medium-9 {
    width: 75%;
  }
  .grid-x > .medium-10 {
    width: 83.3333333333%;
  }
  .grid-x > .medium-11 {
    width: 91.6666666667%;
  }
  .grid-x > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .large-shrink, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex: 0 0 auto;
  }
  .grid-x > .large-shrink {
    width: auto;
  }
  .grid-x > .large-1 {
    width: 8.3333333333%;
  }
  .grid-x > .large-2 {
    width: 16.6666666667%;
  }
  .grid-x > .large-3 {
    width: 25%;
  }
  .grid-x > .large-4 {
    width: 33.3333333333%;
  }
  .grid-x > .large-5 {
    width: 41.6666666667%;
  }
  .grid-x > .large-6 {
    width: 50%;
  }
  .grid-x > .large-7 {
    width: 58.3333333333%;
  }
  .grid-x > .large-8 {
    width: 66.6666666667%;
  }
  .grid-x > .large-9 {
    width: 75%;
  }
  .grid-x > .large-10 {
    width: 83.3333333333%;
  }
  .grid-x > .large-11 {
    width: 91.6666666667%;
  }
  .grid-x > .large-12 {
    width: 100%;
  }
}

.grid-margin-x:not(.grid-x) > .cell {
  width: auto;
}

.grid-margin-y:not(.grid-y) > .cell {
  height: auto;
}

.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
}

.grid-margin-x > .cell {
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x > .cell {
    width: calc(100% - 1.875rem);
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
}

.grid-margin-x > .auto {
  width: auto;
}

.grid-margin-x > .shrink {
  width: auto;
}

.grid-margin-x > .small-1 {
  width: calc(8.3333333333% - 1.25rem);
}

.grid-margin-x > .small-2 {
  width: calc(16.6666666667% - 1.25rem);
}

.grid-margin-x > .small-3 {
  width: calc(25% - 1.25rem);
}

.grid-margin-x > .small-4 {
  width: calc(33.3333333333% - 1.25rem);
}

.grid-margin-x > .small-5 {
  width: calc(41.6666666667% - 1.25rem);
}

.grid-margin-x > .small-6 {
  width: calc(50% - 1.25rem);
}

.grid-margin-x > .small-7 {
  width: calc(58.3333333333% - 1.25rem);
}

.grid-margin-x > .small-8 {
  width: calc(66.6666666667% - 1.25rem);
}

.grid-margin-x > .small-9 {
  width: calc(75% - 1.25rem);
}

.grid-margin-x > .small-10 {
  width: calc(83.3333333333% - 1.25rem);
}

.grid-margin-x > .small-11 {
  width: calc(91.6666666667% - 1.25rem);
}

.grid-margin-x > .small-12 {
  width: calc(100% - 1.25rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x > .auto {
    width: auto;
  }
  .grid-margin-x > .shrink {
    width: auto;
  }
  .grid-margin-x > .small-1 {
    width: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-x > .small-2 {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x > .small-4 {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x > .small-5 {
    width: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .small-7 {
    width: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-x > .small-8 {
    width: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .small-10 {
    width: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-x > .small-11 {
    width: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.875rem);
  }
  .grid-margin-x > .medium-auto {
    width: auto;
  }
  .grid-margin-x > .medium-shrink {
    width: auto;
  }
  .grid-margin-x > .medium-1 {
    width: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-2 {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x > .medium-4 {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-5 {
    width: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .medium-7 {
    width: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-8 {
    width: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .medium-10 {
    width: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-11 {
    width: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-12 {
    width: calc(100% - 1.875rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-x > .large-auto {
    width: auto;
  }
  .grid-margin-x > .large-shrink {
    width: auto;
  }
  .grid-margin-x > .large-1 {
    width: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-2 {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x > .large-4 {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-5 {
    width: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .large-7 {
    width: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-8 {
    width: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .large-10 {
    width: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-11 {
    width: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-12 {
    width: calc(100% - 1.875rem);
  }
}

.grid-padding-x .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-padding-x .grid-padding-x {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-container:not(.full) > .grid-padding-x {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-padding-x > .cell {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.small-up-1 > .cell {
  width: 100%;
}

.small-up-2 > .cell {
  width: 50%;
}

.small-up-3 > .cell {
  width: 33.3333333333%;
}

.small-up-4 > .cell {
  width: 25%;
}

.small-up-5 > .cell {
  width: 20%;
}

.small-up-6 > .cell {
  width: 16.6666666667%;
}

.small-up-7 > .cell {
  width: 14.2857142857%;
}

.small-up-8 > .cell {
  width: 12.5%;
}

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%;
  }
  .medium-up-2 > .cell {
    width: 50%;
  }
  .medium-up-3 > .cell {
    width: 33.3333333333%;
  }
  .medium-up-4 > .cell {
    width: 25%;
  }
  .medium-up-5 > .cell {
    width: 20%;
  }
  .medium-up-6 > .cell {
    width: 16.6666666667%;
  }
  .medium-up-7 > .cell {
    width: 14.2857142857%;
  }
  .medium-up-8 > .cell {
    width: 12.5%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%;
  }
  .large-up-2 > .cell {
    width: 50%;
  }
  .large-up-3 > .cell {
    width: 33.3333333333%;
  }
  .large-up-4 > .cell {
    width: 25%;
  }
  .large-up-5 > .cell {
    width: 20%;
  }
  .large-up-6 > .cell {
    width: 16.6666666667%;
  }
  .large-up-7 > .cell {
    width: 14.2857142857%;
  }
  .large-up-8 > .cell {
    width: 12.5%;
  }
}

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem);
}

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem);
}

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.3333333333% - 1.25rem);
}

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem);
}

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem);
}

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.6666666667% - 1.25rem);
}

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.2857142857% - 1.25rem);
}

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.2857142857% - 1.875rem);
  }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.2857142857% - 1.875rem);
  }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.2857142857% - 1.875rem);
  }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
}

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0;
}

.small-margin-collapse > .cell {
  margin-right: 0;
  margin-left: 0;
}

.small-margin-collapse > .small-1 {
  width: 8.3333333333%;
}

.small-margin-collapse > .small-2 {
  width: 16.6666666667%;
}

.small-margin-collapse > .small-3 {
  width: 25%;
}

.small-margin-collapse > .small-4 {
  width: 33.3333333333%;
}

.small-margin-collapse > .small-5 {
  width: 41.6666666667%;
}

.small-margin-collapse > .small-6 {
  width: 50%;
}

.small-margin-collapse > .small-7 {
  width: 58.3333333333%;
}

.small-margin-collapse > .small-8 {
  width: 66.6666666667%;
}

.small-margin-collapse > .small-9 {
  width: 75%;
}

.small-margin-collapse > .small-10 {
  width: 83.3333333333%;
}

.small-margin-collapse > .small-11 {
  width: 91.6666666667%;
}

.small-margin-collapse > .small-12 {
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .small-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .small-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .small-margin-collapse > .medium-3 {
    width: 25%;
  }
  .small-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .small-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .small-margin-collapse > .medium-6 {
    width: 50%;
  }
  .small-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .small-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .small-margin-collapse > .medium-9 {
    width: 75%;
  }
  .small-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .small-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .small-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .small-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .small-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .small-margin-collapse > .large-3 {
    width: 25%;
  }
  .small-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .small-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .small-margin-collapse > .large-6 {
    width: 50%;
  }
  .small-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .small-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .small-margin-collapse > .large-9 {
    width: 75%;
  }
  .small-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .small-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .small-margin-collapse > .large-12 {
    width: 100%;
  }
}

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0;
}

.small-padding-collapse > .cell {
  padding-right: 0;
  padding-left: 0;
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.3333333333%;
  }
  .medium-margin-collapse > .small-2 {
    width: 16.6666666667%;
  }
  .medium-margin-collapse > .small-3 {
    width: 25%;
  }
  .medium-margin-collapse > .small-4 {
    width: 33.3333333333%;
  }
  .medium-margin-collapse > .small-5 {
    width: 41.6666666667%;
  }
  .medium-margin-collapse > .small-6 {
    width: 50%;
  }
  .medium-margin-collapse > .small-7 {
    width: 58.3333333333%;
  }
  .medium-margin-collapse > .small-8 {
    width: 66.6666666667%;
  }
  .medium-margin-collapse > .small-9 {
    width: 75%;
  }
  .medium-margin-collapse > .small-10 {
    width: 83.3333333333%;
  }
  .medium-margin-collapse > .small-11 {
    width: 91.6666666667%;
  }
  .medium-margin-collapse > .small-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .medium-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .medium-margin-collapse > .medium-3 {
    width: 25%;
  }
  .medium-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .medium-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .medium-margin-collapse > .medium-6 {
    width: 50%;
  }
  .medium-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .medium-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .medium-margin-collapse > .medium-9 {
    width: 75%;
  }
  .medium-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .medium-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .medium-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .medium-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .medium-margin-collapse > .large-3 {
    width: 25%;
  }
  .medium-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .medium-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .medium-margin-collapse > .large-6 {
    width: 50%;
  }
  .medium-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .medium-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .medium-margin-collapse > .large-9 {
    width: 75%;
  }
  .medium-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .medium-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .medium-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.3333333333%;
  }
  .large-margin-collapse > .small-2 {
    width: 16.6666666667%;
  }
  .large-margin-collapse > .small-3 {
    width: 25%;
  }
  .large-margin-collapse > .small-4 {
    width: 33.3333333333%;
  }
  .large-margin-collapse > .small-5 {
    width: 41.6666666667%;
  }
  .large-margin-collapse > .small-6 {
    width: 50%;
  }
  .large-margin-collapse > .small-7 {
    width: 58.3333333333%;
  }
  .large-margin-collapse > .small-8 {
    width: 66.6666666667%;
  }
  .large-margin-collapse > .small-9 {
    width: 75%;
  }
  .large-margin-collapse > .small-10 {
    width: 83.3333333333%;
  }
  .large-margin-collapse > .small-11 {
    width: 91.6666666667%;
  }
  .large-margin-collapse > .small-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .large-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .large-margin-collapse > .medium-3 {
    width: 25%;
  }
  .large-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .large-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .large-margin-collapse > .medium-6 {
    width: 50%;
  }
  .large-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .large-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .large-margin-collapse > .medium-9 {
    width: 75%;
  }
  .large-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .large-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .large-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .large-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .large-margin-collapse > .large-3 {
    width: 25%;
  }
  .large-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .large-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .large-margin-collapse > .large-6 {
    width: 50%;
  }
  .large-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .large-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .large-margin-collapse > .large-9 {
    width: 75%;
  }
  .large-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .large-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .large-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

.small-offset-0 {
  margin-left: 0%;
}

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.625rem);
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.3333333333% + 0.625rem);
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.6666666667% + 0.625rem);
}

.small-offset-3 {
  margin-left: 25%;
}

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.625rem);
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.3333333333% + 0.625rem);
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.6666666667% + 0.625rem);
}

.small-offset-6 {
  margin-left: 50%;
}

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.625rem);
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.3333333333% + 0.625rem);
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.6666666667% + 0.625rem);
}

.small-offset-9 {
  margin-left: 75%;
}

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.625rem);
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.3333333333% + 0.625rem);
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.6666666667% + 0.625rem);
}

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.9375rem);
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.3333333333% + 0.9375rem);
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.6666666667% + 0.9375rem);
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.9375rem);
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.3333333333% + 0.9375rem);
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.6666666667% + 0.9375rem);
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.9375rem);
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.3333333333% + 0.9375rem);
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.6666666667% + 0.9375rem);
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.9375rem);
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.3333333333% + 0.9375rem);
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.6666666667% + 0.9375rem);
  }
}

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.9375rem);
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.3333333333% + 0.9375rem);
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.6666666667% + 0.9375rem);
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.9375rem);
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.3333333333% + 0.9375rem);
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.6666666667% + 0.9375rem);
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.9375rem);
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.3333333333% + 0.9375rem);
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.6666666667% + 0.9375rem);
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.9375rem);
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.3333333333% + 0.9375rem);
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.6666666667% + 0.9375rem);
  }
}

.grid-y {
  display: flex;
  flex-flow: column nowrap;
}

.grid-y > .cell {
  width: auto;
  max-width: none;
}

.grid-y > .auto {
  height: auto;
}

.grid-y > .shrink {
  height: auto;
}

.grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
  flex-basis: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
    flex-basis: auto;
  }
}

.grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
  flex: 0 0 auto;
}

.grid-y > .small-1 {
  height: 8.3333333333%;
}

.grid-y > .small-2 {
  height: 16.6666666667%;
}

.grid-y > .small-3 {
  height: 25%;
}

.grid-y > .small-4 {
  height: 33.3333333333%;
}

.grid-y > .small-5 {
  height: 41.6666666667%;
}

.grid-y > .small-6 {
  height: 50%;
}

.grid-y > .small-7 {
  height: 58.3333333333%;
}

.grid-y > .small-8 {
  height: 66.6666666667%;
}

.grid-y > .small-9 {
  height: 75%;
}

.grid-y > .small-10 {
  height: 83.3333333333%;
}

.grid-y > .small-11 {
  height: 91.6666666667%;
}

.grid-y > .small-12 {
  height: 100%;
}

@media print, screen and (min-width: 40em) {
  .grid-y > .medium-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-y > .medium-shrink, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
    flex: 0 0 auto;
  }
  .grid-y > .medium-shrink {
    height: auto;
  }
  .grid-y > .medium-1 {
    height: 8.3333333333%;
  }
  .grid-y > .medium-2 {
    height: 16.6666666667%;
  }
  .grid-y > .medium-3 {
    height: 25%;
  }
  .grid-y > .medium-4 {
    height: 33.3333333333%;
  }
  .grid-y > .medium-5 {
    height: 41.6666666667%;
  }
  .grid-y > .medium-6 {
    height: 50%;
  }
  .grid-y > .medium-7 {
    height: 58.3333333333%;
  }
  .grid-y > .medium-8 {
    height: 66.6666666667%;
  }
  .grid-y > .medium-9 {
    height: 75%;
  }
  .grid-y > .medium-10 {
    height: 83.3333333333%;
  }
  .grid-y > .medium-11 {
    height: 91.6666666667%;
  }
  .grid-y > .medium-12 {
    height: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y > .large-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-y > .large-shrink, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
    flex: 0 0 auto;
  }
  .grid-y > .large-shrink {
    height: auto;
  }
  .grid-y > .large-1 {
    height: 8.3333333333%;
  }
  .grid-y > .large-2 {
    height: 16.6666666667%;
  }
  .grid-y > .large-3 {
    height: 25%;
  }
  .grid-y > .large-4 {
    height: 33.3333333333%;
  }
  .grid-y > .large-5 {
    height: 41.6666666667%;
  }
  .grid-y > .large-6 {
    height: 50%;
  }
  .grid-y > .large-7 {
    height: 58.3333333333%;
  }
  .grid-y > .large-8 {
    height: 66.6666666667%;
  }
  .grid-y > .large-9 {
    height: 75%;
  }
  .grid-y > .large-10 {
    height: 83.3333333333%;
  }
  .grid-y > .large-11 {
    height: 91.6666666667%;
  }
  .grid-y > .large-12 {
    height: 100%;
  }
}

.grid-padding-y .grid-padding-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-padding-y .grid-padding-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
}

.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-padding-y > .cell {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
}

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
}

.grid-margin-y > .cell {
  height: calc(100% - 1.25rem);
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
}

.grid-margin-y > .auto {
  height: auto;
}

.grid-margin-y > .shrink {
  height: auto;
}

.grid-margin-y > .small-1 {
  height: calc(8.3333333333% - 1.25rem);
}

.grid-margin-y > .small-2 {
  height: calc(16.6666666667% - 1.25rem);
}

.grid-margin-y > .small-3 {
  height: calc(25% - 1.25rem);
}

.grid-margin-y > .small-4 {
  height: calc(33.3333333333% - 1.25rem);
}

.grid-margin-y > .small-5 {
  height: calc(41.6666666667% - 1.25rem);
}

.grid-margin-y > .small-6 {
  height: calc(50% - 1.25rem);
}

.grid-margin-y > .small-7 {
  height: calc(58.3333333333% - 1.25rem);
}

.grid-margin-y > .small-8 {
  height: calc(66.6666666667% - 1.25rem);
}

.grid-margin-y > .small-9 {
  height: calc(75% - 1.25rem);
}

.grid-margin-y > .small-10 {
  height: calc(83.3333333333% - 1.25rem);
}

.grid-margin-y > .small-11 {
  height: calc(91.6666666667% - 1.25rem);
}

.grid-margin-y > .small-12 {
  height: calc(100% - 1.25rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.875rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 1.875rem);
  }
}

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw;
}

.cell .grid-frame {
  width: 100%;
}

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.cell-block-container > .grid-x {
  max-height: 100%;
  flex-wrap: nowrap;
}

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .cell .medium-grid-frame {
    width: 100%;
  }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .medium-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .cell .large-grid-frame {
    width: 100%;
  }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .large-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh;
}

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
}

.cell .grid-y.grid-frame {
  height: 100%;
}

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%;
  }
}

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
}

.grid-margin-y > .cell {
  height: calc(100% - 1.25rem);
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
}

.grid-margin-y > .auto {
  height: auto;
}

.grid-margin-y > .shrink {
  height: auto;
}

.grid-margin-y > .small-1 {
  height: calc(8.3333333333% - 1.25rem);
}

.grid-margin-y > .small-2 {
  height: calc(16.6666666667% - 1.25rem);
}

.grid-margin-y > .small-3 {
  height: calc(25% - 1.25rem);
}

.grid-margin-y > .small-4 {
  height: calc(33.3333333333% - 1.25rem);
}

.grid-margin-y > .small-5 {
  height: calc(41.6666666667% - 1.25rem);
}

.grid-margin-y > .small-6 {
  height: calc(50% - 1.25rem);
}

.grid-margin-y > .small-7 {
  height: calc(58.3333333333% - 1.25rem);
}

.grid-margin-y > .small-8 {
  height: calc(66.6666666667% - 1.25rem);
}

.grid-margin-y > .small-9 {
  height: calc(75% - 1.25rem);
}

.grid-margin-y > .small-10 {
  height: calc(83.3333333333% - 1.25rem);
}

.grid-margin-y > .small-11 {
  height: calc(91.6666666667% - 1.25rem);
}

.grid-margin-y > .small-12 {
  height: calc(100% - 1.25rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.875rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 1.875rem);
  }
}

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.25rem);
}

@media print, screen and (min-width: 40em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem);
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem);
  }
}

.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-left.vertical.menu > li > a {
  justify-content: flex-start;
}

.align-right.vertical.menu > li > a {
  justify-content: flex-end;
}

.align-center.vertical.menu > li > a {
  justify-content: center;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 39.99875em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.99875em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.99875em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

/* VENDORS */
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #333333;
}

.hamburger-box {
  width: 30px;
  height: 14px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;
  height: 2px;
  background-color: #333333;
  border-radius: 0px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -6px;
}

.hamburger-inner::after {
  bottom: -6px;
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid #DDD;
  border-radius: 0px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 10px;
  font-size: 12px;
  line-height: 38px;
  color: #444;
  height: 38px;
  user-select: none;
}

.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  line-height: 38px;
  background-color: #F8f8f8;
  color: #BBB;
  text-align: center;
  font: 0/0 a;
  *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial;
}

.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #BBB;
  border-bottom: none;
}

.selectric-focus .selectric {
  border-color: #aaaaaa;
}

.selectric-hover .selectric {
  border-color: #c4c3c3;
}

.selectric-hover .selectric .button {
  color: #a2a1a1;
}

.selectric-hover .selectric .button:after {
  border-top-color: #a2a1a1;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric {
  border-color: #c4c3c3;
}

.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F8F8F8;
  border: 1px solid #c4c3c3;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  display: block;
  padding: 10px;
  color: #666;
  cursor: pointer;
}

.selectric-items li.selected {
  background: #E0E0E0;
  color: #444;
}

.selectric-items li.highlighted {
  background: #D0D0D0;
  color: #444;
}

.selectric-items li:hover {
  background: #D5D5D5;
  color: #444;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}

.moda {
  animation: fadeIn .3s;
}

@keyframes fadeIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick/slick.eot");
  src: url("../fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick/slick.woff") format("woff"), url("../fonts/slick/slick.ttf") format("truetype"), url("../fonts/slick/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  z-index: 1;
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -10px;
}

@media only screen and (min-width: 768px) {
  .slick-prev {
    left: 0;
  }
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: 2px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -10px;
}

@media only screen and (min-width: 768px) {
  .slick-next {
    right: 0;
  }
}

[dir="rtl"] .slick-next {
  left: 2px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 8px 29px rgba(0, 0, 0, 0.03);
}

@media only screen and (min-width: 768px) {
  .form {
    margin: 0;
  }
}

.form__title {
  margin-bottom: 24px;
}

.form__field + .form__field {
  margin-top: 26px;
}

@media only screen and (min-width: 1200px) {
  .form__field + .form__field {
    margin-top: 34px;
  }
}

.form-field {
  position: relative;
}

.form-field__head {
  margin-bottom: 6px;
}

.form-field__label {
  width: 100%;
  font: 400 1.4375rem / 1.4347826087 "Mukta", sans-serif;
  color: #333333;
}

.form-field__label--textarea {
  text-transform: uppercase;
  color: #aaaaaa;
  font: 400 0.875rem / 1.1428571429 "Mukta", sans-serif;
}

.form-field__label-secondary {
  width: 100%;
  color: #333333;
  text-transform: uppercase;
  font: 500 0.875rem / 1.4285714286 "Mukta", sans-serif;
}

.form-field__input {
  width: 100%;
  transition: width .15s;
}

.form-field__input--icon {
  width: calc(100% - 40px);
  margin-left: 40px;
  border-radius: 0px 2px 2px 0px !important;
}

.form-field__select {
  width: 100%;
}

.form-field__textarea {
  width: 100%;
  resize: vertical;
  box-sizing: border-box;
}

.form-field__icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
  padding: 8px;
  border: 1px solid #d7d7d7;
  border-right: none;
}

.form-field__icon svg {
  fill: blue;
  height: 100%;
}

.form-field__wrapper {
  position: relative;
}

.form-field__error {
  display: none;
  margin-top: 6px;
  color: #a13602;
  font: 400 0.875rem / 1 hel-light;
}

.form-field__error:empty {
  display: none;
}

.form-field__valid {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border: 1px solid #d7d7d7;
  border-left: none;
  transform: scale3d(0, 1, 1);
  transform-origin: center right;
  transition: transform .15s;
}

.form-field__valid span {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 100%;
  transition: border-color .2s;
}

.form-field__valid span::after {
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 14px;
  width: 18%;
  height: 36%;
  transform: scaleX(-1) rotate(135deg);
  transform-origin: top left;
  transition: border-color .15s .3s;
}

.form-field__half + .form-field__half {
  margin-top: 34px;
}

@media only screen and (min-width: 1200px) {
  .form-field__half + .form-field__half {
    margin-top: 0;
  }
}

.form-field__desc {
  color: #333333;
  font: 400 1rem / 1.75 "Mukta", sans-serif;
}

.form-field__tip {
  margin-top: 15px;
}

.form-field__radio {
  display: block;
  padding: 25px 20px;
  border: 1px solid #333333;
  cursor: pointer;
}

.form-field__radio + .form-field__radio {
  margin-top: 10px;
}

.form-field__radio .md-input {
  display: none;
  margin-top: 20px;
}

@media only screen and (min-width: 1200px) {
  .form-field-half {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.form-field-half > * {
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .form-field-half > * {
    flex: 0 1 48%;
  }
}

@keyframes check-valid {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 18%;
  }
  50% {
    height: 36%;
    width: 18%;
  }
}

header.page-header {
  z-index: 1;
  height: 23.4375rem;
}

header.page-header::before, header.page-header::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

header.page-header::after {
  clear: both;
}

header.page-header > .grid-container {
  min-height: 23.4375rem;
  background: url("../images/shapes/shape-header.svg") 0 0/91% auto no-repeat;
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  header.page-header {
    height: 15.625rem;
  }
  header.page-header > .grid-container {
    min-height: 15.625rem;
    background-position: 50% 0;
    background-size: 63% auto;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.99875em) {
  header.page-header {
    height: 12.5rem;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 30%, transparent 30%, transparent 100%);
  }
  header.page-header > .grid-container {
    min-height: 12.5rem;
  }
}

@media screen and (max-width: 39.99875em) {
  header.page-header {
    height: 7.1875rem;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 52%, transparent 52%, transparent 100%);
  }
  header.page-header > .grid-container {
    min-height: 7.1875rem;
    background: none;
  }
}

.top-logo {
  width: 15.0625rem;
  top: 2.5625rem;
  left: 2.8125rem;
  right: auto;
  bottom: auto;
  position: absolute;
}

@media screen and (max-width: 63.99875em) {
  .top-logo {
    width: 10.625rem;
    z-index: 101;
    top: 0.625rem;
    left: 0.9375rem;
    right: auto;
    bottom: auto;
    position: absolute;
  }
  .top-logo.open {
    position: fixed;
  }
}

.top-logo__404 {
  padding: 3rem 0 1.3125rem;
  text-align: center;
}

.top-logo__404::before, .top-logo__404::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.top-logo__404::after {
  clear: both;
}

.top-logo__404 a {
  width: 15.0625rem;
  display: inline-block;
}

@media screen and (max-width: 63.99875em) {
  .top-logo__404 a {
    width: 10.625rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .top-logo {
    left: 1.5625rem;
  }
}

.main-menu {
  padding-top: 2rem;
  text-align: center;
  letter-spacing: -0.3em;
}

.main-menu > li {
  padding: 0 2.8125rem;
  display: inline-block;
  font-size: 1.375rem;
  font-family: "Exo2-Light";
  letter-spacing: normal;
  position: relative;
}

.main-menu > li > a {
  position: relative;
  z-index: 5;
  color: #333;
  text-decoration: none;
}

.main-menu > li > a:hover {
  color: #2181c4;
}

.main-menu > li > a:hover {
  text-decoration: none;
}

.main-menu > li > a.has-dropdown:hover {
  background: none;
}

.main-menu > li:hover .main-menu__sub {
  display: block;
}

.main-menu > li:hover a.has-dropdown {
  color: #72d4cf;
}

@media screen and (max-width: 63.99875em) {
  .main-menu > li:hover a.has-dropdown {
    color: #2181c4;
  }
}

.main-menu__sub > li {
  position: relative;
  z-index: 1;
}

.main-menu__sub > li > a {
  display: inline-block;
}

.main-menu__sub > li > a.has-external {
  padding-right: 1.4375rem;
  background: url("../images/arrow.svg") 100% 70%/16px auto no-repeat;
}

@media print, screen and (min-width: 64em) {
  .main-menu__sub {
    width: 18.0625rem;
    min-height: 19.9375rem;
    padding-top: 6.25rem;
    padding-bottom: 3.125rem;
    background: url("../images/bg-menu-dropdown-top.png") 0 0/contain no-repeat;
    transform: translateX(-50%);
    z-index: 3;
    display: none;
    filter: drop-shadow(0 0.5625rem 3.0625rem rgba(66, 158, 223, 0.76));
    top: -0.4375rem;
    left: 50%;
    right: auto;
    bottom: auto;
    position: absolute;
  }
  .main-menu__sub:before {
    content: "";
    display: inline-block;
    height: 11.1875rem;
    background: url("../images/bg-menu-dropdown-bottom.png") 0 0/contain no-repeat;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }
  .main-menu__sub:after {
    content: "";
    display: inline-block;
    background: url("../images/px-menu-dropdown.png") 0 0/contain repeat-y;
    top: 8.6875rem;
    left: 0;
    right: 0;
    bottom: 11.1875rem;
    position: absolute;
  }
  .main-menu__sub > li {
    padding: 0.9375rem 1.5625rem 0;
    font-family: "Exo2-Light";
    font-size: 1.5625rem;
    font-weight: normal;
    line-height: 1.1;
  }
  .main-menu__sub > li a {
    color: #fff;
  }
  .main-menu__sub > li a:hover {
    color: #72d4cf;
  }
}

@media screen and (max-width: 63.99875em) {
  .main-menu__sub > li {
    padding: 1.5625rem 0.9375rem 0;
  }
  .main-menu__sub > li a {
    color: #aaa;
  }
  .main-menu__sub > li a:hover {
    color: #2181c4;
  }
}

.main-menu__dropdown {
  width: 2.25rem;
  height: 2.25rem;
  display: block;
  border-bottom: 2px solid #333;
  cursor: pointer;
  position: relative;
  z-index: 102;
  top: 0;
  left: auto;
  right: 0.9375rem;
  bottom: auto;
  position: absolute;
}

.main-menu__dropdown:before {
  content: "";
  display: inline-block;
  border-top: 2px solid #333;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0.3125rem;
  position: absolute;
}

.main-menu__dropdown:after {
  content: "";
  display: inline-block;
  border-top: 2px solid #333;
  top: auto;
  left: 0.75rem;
  right: 0;
  bottom: 0.75rem;
  position: absolute;
}

.main-menu__dropdown.open {
  border: 0;
  position: fixed;
}

.main-menu__dropdown.open:before {
  transform: rotate(45deg);
}

.main-menu__dropdown.open:after {
  transform: rotate(-45deg);
  top: auto;
  left: 0;
  right: 0;
  bottom: 0.3125rem;
  position: absolute;
}

@media only screen and (min-width: 1024px) {
  .main-menu {
    padding-top: 0.4375rem;
  }
  .main-menu > li {
    padding: 0 0.0625rem;
    font-family: "Exo2-Light";
    font-size: 1.375rem;
    font-weight: normal;
    line-height: 3.5rem;
  }
  .main-menu > li > a {
    min-width: 6.875rem;
    padding: 0.625rem;
    display: inline-block;
    text-align: center;
    color: #333;
  }
  .main-menu > li > a:hover {
    color: #76d9d1;
  }
  .main-menu > li > a:hover {
    background: url("../images/menu-hover-shape.svg") 50% 50%/100% auto no-repeat;
  }
}

@media screen and (max-width: 63.99875em) {
  .main-menu {
    padding: 3.625rem 0 5rem;
    background-color: #f5fafe;
    z-index: 100;
    display: none;
    box-shadow: 0 5px 10px #a7adb1;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    position: absolute;
  }
  .main-menu.open {
    position: fixed;
  }
  .main-menu > li {
    padding: 1.5625rem 0.9375rem 0;
    display: block;
  }
}

@media screen and (max-width: 1500px) {
  .main-menu {
    padding-left: 7%;
  }
}

.dd-trigger {
  position: relative;
}

.dd-trigger:hover .dd-content, .dd-trigger:active .dd-content {
  max-height: 700px;
}

@media only screen and (min-width: 1200px) {
  .dd-trigger:hover .dd-content, .dd-trigger:active .dd-content {
    opacity: 1;
    visibility: visible;
    transition: .3s;
    max-height: none;
  }
}

@media only screen and (min-width: 1200px) {
  .dd-trigger:hover .dd-content--2column, .dd-trigger:active .dd-content--2column {
    max-height: 340px;
  }
}

.dd-trigger:hover .dd-content--no-anim, .dd-trigger:active .dd-content--no-anim {
  transition: none;
}

.dd-trigger__arrow {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  fill: #000000;
}

.dd-trigger--pointer {
  cursor: pointer;
}

.dd-content {
  overflow: hidden;
  max-height: 0;
  transition: .2s ease-out;
}

@media only screen and (min-width: 1200px) {
  .dd-content {
    overflow: visible;
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    z-index: 1;
    display: flex;
    flex-flow: column wrap;
    max-height: none;
    padding: 24px 0;
    background-color: #ffffff;
    border: 1px dashed #333333;
    color: #1a1919;
    opacity: 0;
    visibility: hidden;
    backface-visibility: hidden;
    transform: translate3d(-50%, 0, 0);
    transition: none;
  }
}

@media only screen and (min-width: 1200px) {
  .dd-content::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: 24px;
  }
}

@media only screen and (min-width: 1200px) {
  .dd-content--2column {
    width: 490px;
    max-height: 340px;
  }
}

.dd-content--pt0 {
  padding-top: 0;
}

.dd-content__list {
  padding: 16px 0;
  border-top: 2px solid #333333;
}

@media only screen and (min-width: 1200px) {
  .dd-content__list {
    padding: 0;
    border: none;
    display: flex;
    flex-flow: column wrap;
  }
}

@media only screen and (min-width: 1200px) {
  .dd-content__item {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) {
  .dd-content__item--first {
    padding: 16px 40px;
    margin-bottom: 16px;
    border-bottom: 1px dashed #333333;
  }
}

.dd-content__link {
  position: relative;
  display: block;
  padding: 8px 0;
  color: #333333;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  font: 400 1.125rem / 1.1111111111 "Mukta", sans-serif;
}

@media only screen and (min-width: 1200px) {
  .dd-content__link {
    display: inline-block;
    padding: 8px 0;
    text-align: left;
  }
}

@media only screen and (min-width: 1200px) {
  .dd-content__link--green {
    color: #00a196;
  }
}

.hamburger {
  position: relative;
  z-index: 1;
}

.page-footer {
  min-height: 18.75rem;
  padding-top: 6.875rem;
  padding-bottom: 1.875rem;
  background: url("../images/footer-bg.svg") 50% 100%/cover no-repeat;
  color: #fff;
  font: 400 1.0625rem / 1.2941176471 "Muli-Regular";
}

.page-footer__title {
  color: #76d9d1;
  font: 400 1.125rem / 1.2222222222 "Muli-SemiBold";
}

.page-footer p.text {
  padding-top: 23px;
}

@media screen and (max-width: 39.99875em) {
  .page-footer__title {
    padding-top: 2.125rem;
  }
}

.footer-logo {
  width: 18.75rem;
}

@media screen and (max-width: 63.99875em) {
  .footer-logo {
    width: 100%;
    max-width: 250px;
  }
}

.alert-success {
  padding: 0.625rem 1.25rem;
  border-radius: 8px;
  color: #206730;
  background-color: #cfffdb;
  border-color: #c3e6cb;
  font-family: "Exo2-Light";
  font-size: 1.375rem;
  font-weight: normal;
  line-height: 1.5;
}

.object-fit-cover {
  position: relative;
  background-size: cover;
  background-position: center center;
}

.object-fit-cover img {
  opacity: 0;
}

/* <ul class="inline-children--block sg-icons">
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg></li>
</ul>

Use `inline` when used with text:
```
<p>
	<svg class="icon icon--inline"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg>
	Lorem ipsum dolor sit amet, consectetur
	<svg class="icon icon--inline"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg>
	adipisicing elit.
	<svg class="icon icon--inline"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg>
</p>

Use `icon-text` for vertical alignment:
```
<div style="max-width: 10em">
	<span class="icon-text">
		<span class="icon-text__t">Icon text, description or title</span>
		<svg class="icon-text__i icon">
			<use xlink:href="/assets/img/sprite.svg#plus"></use>
		</svg>
	</span>
</div>
*/
.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon--inline {
  vertical-align: n(11.1111111111%);
}

.icon-text {
  display: inline-block;
  position: relative;
}

.icon-text__i {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.icon-text__i:first-child {
  right: auto;
  left: 0;
}

.icon-text__t {
  display: inline-block;
  padding-right: calc($icon-size + $rel-spacing-unit--small);
}

.icon-text__t:last-child {
  padding-right: 0;
  padding-left: calc($icon-size + $rel-spacing-unit--small);
}

.icon-hamburger {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
}

.icon-hamburger span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 60%;
  height: 2px;
  background-color: #d7d7d7;
  border-radius: 2px;
}

.icon-hamburger span::before, .icon-hamburger span::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: inherit;
  background-color: inherit;
}

.icon-hamburger span::before {
  top: -4px;
}

.icon-hamburger span::after {
  bottom: -4px;
}

.icon-kebab {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 100%;
  cursor: pointer;
}

.icon-kebab span {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 4px;
  height: 4px;
  background-color: #86acff;
  border-radius: 50%;
}

.icon-kebab span::before, .icon-kebab span::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: inherit;
}

.icon-kebab span::before {
  top: -6px;
}

.icon-kebab span::after {
  top: 6px;
}

.icon-info {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-radius: 50%;
  border: 2px solid #aaaaaa;
  color: #aaaaaa;
  transform: rotateX(180deg);
}

.icon-info--green {
  border-color: #00a196;
  color: #00a196;
}

.icon-info span {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 11px;
}

.icon-file-wrap {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}

.icon-file-wrap::before {
  content: attr(data-ext);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000;
  text-align: center;
  text-transform: uppercase;
  font: 700 0.625rem / 1.6 "Mukta", sans-serif;
}

@media only screen and (min-width: 1200px) {
  .icon-file-wrap::before {
    font: 700 0.875rem / 1.4285714286 "Mukta", sans-serif;
  }
}

.btn {
  display: inline-block;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  text-align: center;
  text-decoration: none;
}

.btn--s {
  padding: 8px 16px;
  font-size: 0.875rem;
}

.btn--l {
  padding: 12px 20px;
  font-size: 0.9375rem;
}

.btn--center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn--right {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.btn-primary {
  min-width: 210px;
  padding: 8px 18px;
  border-radius: 10px;
  background-color: #00a196;
  color: #ffffff;
  text-transform: uppercase;
  font: 400 1.0625rem / 2 "Mukta", sans-serif;
  transition: .3s;
}

@media only screen and (min-width: 1200px) {
  .btn-primary {
    padding: 10px 18px;
    font: 400 1.4375rem / 1.5217391304 "Mukta", sans-serif;
  }
}

.btn-primary:hover, .btn-primary:focus {
  background-color: #009288;
}

.btn-primary:active {
  background-color: #00a196;
}

.btn-primary[disabled] {
  background-color: #aaaaaa;
  cursor: not-allowed;
  user-select: none;
}

.btn-primary__icon {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 16px;
  fill: green;
  transition: .2s;
}

.btn-primary__text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
  transition: .2s;
}

.btn-primary.is-active .btn-primary__icon {
  width: 16px;
  transition: .2s .25s;
}

.btn-primary.is-active .btn-primary__text {
  margin-left: 6px;
  transition: .2s .25s;
}

.btn-secondary {
  padding: 6px 18px;
  border-radius: 5px;
  background-color: transparent;
  color: #333333;
  border: 1px solid #333333;
  text-transform: uppercase;
  letter-spacing: .04em;
  font: 400 0.9375rem / 2.3333333333 "Mukta", sans-serif;
  transition: .3s;
}

.btn-secondary:hover, .btn-secondary:focus {
  background-color: #333333;
  color: #ffffff;
}

.btn-secondary[disabled] {
  background-color: transparent;
  color: #333333;
  cursor: not-allowed;
  user-select: none;
}

.btn-tertiary {
  padding: 10px 18px;
  border-radius: 30px;
  background-color: blue;
  color: #ffffff;
  font: 400 0.9375rem / 1 hel-light;
  text-transform: uppercase;
  transition: .3s;
}

.btn-tertiary:visited {
  color: #ffffff;
}

.btn-tertiary:hover, .btn-tertiary:focus {
  background-color: #0c0cff;
  color: #ffffff;
}

.btn-tertiary:active {
  background-color: #0000f9;
  color: #ffffff;
}

.btn-tertiary[disabled] {
  cursor: not-allowed;
  user-select: none;
  opacity: .6;
}

.btn-tertiary[disabled]:hover, .btn-tertiary[disabled]:focus {
  background-color: blue;
  color: #ffffff;
}

.btn-tertiary[disabled]:active {
  background-color: blue;
  color: #ffffff;
}

.btn-tertiary .icon-checked {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #0000cc;
}

.btn-tertiary .icon-checked svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 50%;
  fill: #ffffff;
}

.btn-tertiary .plus {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #0000cc;
}

.btn-tertiary .plus::before, .btn-tertiary .plus::after {
  content: "";
  display: block;
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}

.btn-tertiary .plus::before {
  width: 2px;
  height: 45%;
}

.btn-tertiary .plus::after {
  width: 45%;
  height: 2px;
}

.btn-tertiary__icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  fill: #ffffff;
}

.btn-tertiary__text {
  display: inline-block;
  vertical-align: middle;
}

.btn-close {
  position: absolute;
  top: 14px;
  right: 14px;
  width: 16px;
  height: 16px;
}

.btn-close::before, .btn-close::after {
  content: "";
  display: block;
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background-color: #000000;
}

.btn-close::before {
  transform: rotate(45deg);
}

.btn-close::after {
  transform: rotate(-45deg);
}

.link {
  position: relative;
  display: inline-block;
  color: #00a196;
  font: 400 0.9375rem / 1.6 "Mukta", sans-serif;
  transition: .2s;
}

.link::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #00a196;
  transition: .2s;
}

.link:hover, .link:focus {
  color: #00d4c6;
}

.link:hover::before, .link:focus::before {
  width: 100%;
}

.link:active {
  color: #00a196;
}

.link:active::before {
  width: 0;
}

.link--underline::before {
  width: 100%;
}

.link--underline:hover::before {
  width: 0;
}

.link--empty::before {
  display: none;
}

.link-secondary {
  position: relative;
  display: inline-block;
  color: #333333;
  font: 400 0.9375rem / 1.6 "Mukta", sans-serif;
  transition: .2s;
}

.link-secondary::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #333333;
  transition: .2s;
}

.link-secondary:hover, .link-secondary:focus {
  color: #4d4c4c;
}

.link-secondary:hover::before, .link-secondary:focus::before {
  opacity: 0;
}

.link-secondary:active {
  color: #333333;
}

.link-secondary:active::before {
  opacity: 1;
}

.link-tertiary {
  position: relative;
  display: inline-block;
  color: #333333;
  text-transform: uppercase;
  font: 500 1.125rem / 1.4444444444 "Mukta", sans-serif;
}

@media only screen and (min-width: 1200px) {
  .link-tertiary {
    font: 400 2.5rem / 1 "Mukta", sans-serif;
  }
}

.link-tertiary:hover::before {
  left: calc(100% - 24px);
}

@media only screen and (min-width: 1200px) {
  .link-tertiary:hover::before {
    left: calc(100% - 50px);
  }
}

.link-tertiary::before {
  content: "";
  display: block;
  position: absolute;
  top: 42%;
  left: -24px;
  width: 50px;
  height: 1px;
  pointer-events: none;
  background-color: #333333;
  transition: 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media only screen and (min-width: 1200px) {
  .link-tertiary::before {
    width: 100px;
    height: 3px;
    left: -50px;
  }
}

.radio-row + .radio-row {
  margin-top: 16px;
}

[type="radio"]:checked, [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
  position: relative;
  display: inline-block;
  padding-left: 24px;
  cursor: pointer;
  color: #333;
  font: 400 1rem / 1.125 "Mukta", sans-serif;
}

[type="radio"]:checked + label::before, [type="radio"]:not(:checked) + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 1px;
  width: 16px;
  height: 16px;
  border: 1px solid #333333;
  border-radius: 100%;
  background: #ffffff;
  transition: border-color .2s;
}

[type="radio"]:checked + label::after, [type="radio"]:not(:checked) + label::after {
  content: '';
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  position: absolute;
  top: 5px;
  left: 4px;
  border-radius: 50%;
  transition: .2s ease;
}

[type="radio"]:not(:checked) + label:hover::before {
  border-color: #333333;
}

[type="radio"]:not(:checked) + label::after {
  opacity: 0;
  transform: scale(0);
}

[type="radio"]:checked + label::before {
  background-color: #00a196;
  border-color: #00a196;
}

[type="radio"]:checked + label::after {
  opacity: 1;
  transform: scale(1);
}

[type="radio"]:checked ~ .md-input {
  display: block;
}

.radio-wrap {
  display: inline-block;
}

.radio-wrap + .radio-wrap {
  margin-left: 20px;
}

.radio-label {
  display: inline-flex;
}

.radio-label__cb {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.radio-label__cb:checked ~ .radio-label__label {
  border-color: #333333;
}

.radio-label__cb:checked ~ .radio-label__label .radio-label__checkmark {
  display: inline-block;
}

.radio-label__cb:checked ~ .radio-label__label .radio-label__plus {
  display: none;
}

.radio-label__label {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid transparent;
  background-color: #edeff3;
  cursor: pointer;
  transition: .2s;
}

.radio-label__title {
  color: #333333;
  font: 500 1rem / 1.5 "Mukta", sans-serif;
}

.radio-label__plus {
  position: relative;
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-left: 10px;
}

.radio-label__plus::before, .radio-label__plus::after {
  content: "";
  display: inline-block;
  position: absolute;
  background-color: #aaaaaa;
}

.radio-label__plus::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
}

.radio-label__plus::after {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 1px;
  height: 100%;
}

.radio-label__checkmark {
  display: none;
  margin-left: 10px;
}

.radio-label__svg {
  width: 17px;
  height: 10px;
  fill: none;
  stroke: #000000;
  stroke-width: 2px;
}

select {
  position: relative;
  padding: 10px 24px 10px 16px;
  appearance: none;
  border-radius: 2px;
  border: 1px solid #333333;
  background: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1MS44NDcgNDUxLjg0NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDUxLjg0NyA0NTEuODQ3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTIyNS45MjMsMzU0LjcwNmMtOC4wOTgsMC0xNi4xOTUtMy4wOTItMjIuMzY5LTkuMjYzTDkuMjcsMTUxLjE1N2MtMTIuMzU5LTEyLjM1OS0xMi4zNTktMzIuMzk3LDAtNDQuNzUxICAgYzEyLjM1NC0xMi4zNTQsMzIuMzg4LTEyLjM1NCw0NC43NDgsMGwxNzEuOTA1LDE3MS45MTVsMTcxLjkwNi0xNzEuOTA5YzEyLjM1OS0xMi4zNTQsMzIuMzkxLTEyLjM1NCw0NC43NDQsMCAgIGMxMi4zNjUsMTIuMzU0LDEyLjM2NSwzMi4zOTIsMCw0NC43NTFMMjQ4LjI5MiwzNDUuNDQ5QzI0Mi4xMTUsMzUxLjYyMSwyMzQuMDE4LDM1NC43MDYsMjI1LjkyMywzNTQuNzA2eiIgZmlsbD0iIzJkMmQyZCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo") calc(100% + 8px - 16px) 50%/8px auto no-repeat #ffffff;
  color: #000000;
  font: 400 1rem / 1.125 "Mukta", sans-serif;
}

select:focus {
  border-color: #00a196;
  transition: .15s;
}

select option {
  font: 400 1rem / 1 hel-light;
}

select[disabled] {
  background-color: #aaaaaa;
  opacity: .8;
  color: #b5b5b5;
}

input {
  padding: 15px 16px;
  border: 1px solid #333333;
  background-color: #ffffff;
  color: #333333;
  font: 400 1rem / 1.125 "Mukta", sans-serif;
}

input:focus {
  border-color: #00a196;
  transition: border-color .15s;
}

input::placeholder {
  color: #aaaaaa;
}

input[disabled] {
  background: #f3f3f3;
  opacity: .8;
}

input[type="color"] {
  visibility: hidden;
  opacity: 0;
}

textarea {
  min-height: 100px;
  padding: 15px 16px;
  border: 1px solid #333333;
  background-color: #ffffff;
  color: #333333;
  font: 400 1rem / 1.125 "Mukta", sans-serif;
}

textarea:focus {
  border-color: #00a196;
  transition: border-color .15s;
}

textarea::placeholder {
  color: #aaaaaa;
}

textarea[disabled] {
  background: #f3f3f3;
  opacity: .8;
}

.textarea {
  padding: 10px 16px;
  border: 2px solid #333333;
  background-color: transparent;
  color: #333333;
  font: 400 1rem / 1.125 "Mukta", sans-serif;
}

.textarea:focus {
  border-color: #00a196;
  transition: border-color .15s;
}

.textarea::placeholder {
  color: #aaaaaa;
}

.textarea[disabled] {
  background: #f3f3f3;
  opacity: .8;
}

/*
<div class="checkbox">
    <input type="checkbox" id="cb" name="cb" />
    <label for="cb"></label>
    <label for="cb" class="checkbox__text">Text</label>
</div>
*/
@keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 40%;
  }
  50% {
    height: 70%;
    width: 40%;
  }
}

.checkbox {
  display: flex;
  flex-flow: row nowrap;
}

.checkbox__text {
  cursor: pointer;
  color: #333333;
  font: 400 1rem / 1.125 "Mukta", sans-serif;
}

.checkbox label:not([class]) {
  position: relative;
  flex: 0 0 auto;
  margin-right: 8px;
  height: 19px;
  width: 19px;
  border: 2px solid #333333;
  border-radius: 1px;
  cursor: pointer;
  background-color: #ffffff;
  transition: border-color .2s;
}

@media only screen and (min-width: 1200px) {
  .checkbox label:not([class]) {
    margin-right: 14px;
  }
}

.checkbox label:not([class])::after {
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  left: 2px;
  width: 40%;
  height: 70%;
  /*
            This element has a border on the right, and top. By rotating it looks like a check symbol, but the wrong way. That's why I'm flipping it with scaleX(-1)
            */
  transform: scaleX(-1) rotate(135deg);
  /*
            I want to position the symbol using the left top, because when increasing the width and height of an object, it expands from the top left corner as well. That is what makes it looks like it's being drawn.
            */
  transform-origin: top left;
}

.checkbox input {
  display: none;
}

.checkbox input:not(:checked) + label:hover {
  border-color: #00a196;
}

.checkbox input:checked + label::after {
  animation: check 0.3s;
  border-color: #00a196;
}

.checkbox.checkbox-alt label:hover:after {
  animation: check 0.6s ease infinite;
}

.checkbox.checkbox-alt input:checked + label:after {
  animation: none;
}

.subscribe-input {
  position: relative;
}

.subscribe-input__input {
  width: 100%;
  padding: 18px 50px 18px 18px;
  border: 1px solid #505050;
  border-radius: 2px;
  background-color: #333333;
  color: #ffffff;
  text-transform: uppercase;
  font: 400 1.0625rem / 1.1176470588 "Mukta", sans-serif;
}

@media only screen and (min-width: 1200px) {
  .subscribe-input__input {
    font: 400 0.9375rem / 1.1333333333 "Mukta", sans-serif;
  }
}

.subscribe-input__btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  padding: 12px;
  border-radius: 0 2px 2px 0;
  background-color: #00a196;
}

.subscribe-input__icon {
  fill: transparent;
  stroke: #ffffff;
}

.card {
  padding: 30px 20px;
  border: 1px solid #333333;
  background-color: #ffffff;
}

@media only screen and (min-width: 1200px) {
  .card {
    padding: 60px;
  }
}

.card--disabled {
  opacity: .4;
}

.card__head {
  margin-bottom: 16px;
}

.radio-card {
  display: block;
  padding: 20px 18px;
  cursor: pointer;
  border: 1px solid #333333;
  background-color: #ffffff;
}

@media only screen and (min-width: 1200px) {
  .radio-card {
    padding: 24px;
  }
}

.radio-card--disabled {
  opacity: .5;
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.radio-card--disabled * {
  cursor: default !important;
}

.radio-card__tip {
  margin-left: 24px;
  color: #aaaaaa;
  font: 400 0.8125rem / 1.5384615385 "Mukta", sans-serif;
}

.radio-card__desc {
  margin-top: 22px;
}

@media only screen and (min-width: 768px) {
  .radio-card__desc {
    margin-top: 28px;
    margin-left: 24px;
  }
}

.radio-card__row {
  margin-top: 22px;
}

@media only screen and (min-width: 768px) {
  .radio-card__row {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) {
  .radio-card__row + .radio-card__row {
    margin-top: 12px;
  }
}

.radio-card__title {
  color: #333333;
  font: 700 1rem / 1.25 "Mukta", sans-serif;
}

.radio-card__text {
  margin-top: 10px;
  color: #333333;
  font: 400 1rem / 1.5 "Mukta", sans-serif;
}

.radio-card__text > * {
  font: 400 1rem / 1.5 "Mukta", sans-serif;
}

.radio-card__heading-2 {
  color: #aaaaaa;
  text-transform: uppercase;
  font: 400 0.875rem / 1.4285714286 "Mukta", sans-serif;
}

.radio-card__text-2 {
  color: #333333;
  font: 700 1rem / 1.5 "Mukta", sans-serif;
}

.radio-card__text-2 > * {
  font: 700 1rem / 1.5 "Mukta", sans-serif;
}

.radio-card__badge {
  margin-top: 14px;
}

.radio-card__actions {
  display: none;
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .radio-card__actions {
    margin-left: 24px;
  }
}

.radio-card__action-row + .radio-card__action-row {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .radio-card__action-row + .radio-card__action-row {
    margin-top: 0;
  }
}

.radio-card.is-checked .radio-card__actions {
  display: block;
}

.socials {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

@media only screen and (min-width: 1200px) {
  .socials {
    justify-content: flex-start;
  }
}

.socials__link {
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 12px 0;
  border-radius: 50%;
  background-color: #333333;
  transition: .3s;
}

.socials__link:hover {
  background-color: #00a196;
}

@media only screen and (min-width: 1200px) {
  .socials__link + .socials__link {
    margin-left: 35px;
  }
}

.socials__icon {
  fill: #ffffff;
}

hr,
.hr {
  height: 1px;
  background-color: black;
  border: none;
}

.hr--grey-light {
  background-color: grey;
}

/*
Inline list
```
<ul class="inline-children--block">
	<li>item 1</li>
	<li>item 2</li>
	<li>item 3</li>
	<li>item 4</li>
	<li>item 5</li>
</ul>
```
*/
.ol,
.ul {
  margin-left: rem(30px);
  margin-right: rem(30px);
}

.ol > li,
.ul > li {
  margin-top: 0.5em;
}

.ol > li:first-child,
.ul > li:first-child {
  margin-top: 0;
}

.ol {
  list-style-type: decimal;
}

.ul {
  list-style-type: disc;
}

.badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #edeff3;
  color: #333333;
  font: 700 0.8125rem / 1.5384615385 "Mukta", sans-serif;
}

.badge--not-available {
  color: #c4140f;
}

.badge--available {
  color: #00a196;
}

.badge--active {
  color: #00a196;
}

.badge--pending {
  color: #e89902;
}

.badge--drafted {
  color: #333333;
}

.badge--successfully {
  color: #0baa0b;
}

.badge--unsuccessfully {
  color: #c4140f;
}

.custom-upload {
  position: relative;
  display: block;
  padding: 40px 30px;
  border: 1px dashed #aaaaaa;
  background-color: #ffffff;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .custom-upload {
    padding: 40px;
  }
}

.custom-upload.is-uploading .custom-upload__loading {
  display: flex;
}

.custom-upload.is-uploading .lds-roller {
  display: inline-block;
}

.custom-upload.is-uploaded {
  padding: 0;
  border-color: transparent;
}

.custom-upload.is-uploaded .custom-upload__default {
  display: none;
}

.custom-upload.is-uploaded .custom-upload__uploaded {
  display: flex;
}

.custom-upload__icon {
  margin-bottom: 14px;
  height: 38px;
  fill: none;
  stroke: #aaaaaa;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.custom-upload__desc {
  color: #333333;
  font: 400 1rem / 1.75 "Mukta", sans-serif;
}

.custom-upload__notice {
  color: #aaaaaa;
  font: 400 0.9375rem / 1.8666666667 "Mukta", sans-serif;
}

.custom-upload__loading {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.custom-upload__loading-percent {
  color: #333333;
  font: 400 1rem / 1.75 "Mukta", sans-serif;
}

.custom-upload__loading-bar {
  position: relative;
  width: 80%;
  height: 10px;
  background-color: #edeff3;
}

.custom-upload__loading-line {
  position: absolute;
  left: 0;
  top: 0;
  width: 20%;
  height: 100%;
  background-color: #000000;
  transition: .2s;
}

.custom-upload__uploaded {
  display: none;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  text-align: left;
}

.custom-upload__img-thumb {
  max-height: 370px;
}

.custom-upload input[type="file"] {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.custom-upload label {
  display: block;
  width: 100%;
  height: 107px;
  cursor: pointer;
  background-size: 100% 80%;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-upload .lds-roller {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-upload-secondary {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.custom-upload-secondary__label {
  flex: 0 0 auto;
  display: inline-block;
  padding: 15px 24px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #333333;
  color: #ffffff;
  text-transform: uppercase;
  font: 400 1.0625rem / 1.1764705882 "Mukta", sans-serif;
}

.custom-upload-secondary__name {
  display: inline-block;
  padding-left: 14px;
  color: #333333;
  font: 400 1rem / 1.75 "Mukta", sans-serif;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-upload-secondary input[type="file"] {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.b-avatar {
  display: inline-block;
}

.b-avatar img {
  display: block;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@media only screen and (min-width: 768px) {
  .b-avatar img {
    width: 50px;
    height: 50px;
  }
}

.l-breadcrumbs {
  margin-bottom: 40px;
}

@media only screen and (min-width: 1200px) {
  .l-breadcrumbs {
    margin-bottom: 60px;
  }
}

.b-breadcrumbs {
  padding-bottom: 10px;
  border-bottom: 12px solid #00a196;
}

.b-breadcrumbs__item {
  position: relative;
}

.b-breadcrumbs__item + .b-breadcrumbs__item {
  margin-left: 8px;
}

.b-breadcrumbs__item + .b-breadcrumbs__item::before {
  content: "/";
  display: inline-block;
  position: relative;
  margin-right: 6px;
  font: 600 1.25rem / 1.3 "Sarabun", sans-serif;
}

@media only screen and (min-width: 1200px) {
  .b-breadcrumbs__item + .b-breadcrumbs__item::before {
    font: 600 1.75rem / 1.2142857143 "Sarabun", sans-serif;
  }
}

.b-breadcrumbs__item:first-child .b-breadcrumbs__link {
  font: 600 1.1875rem / 1.4736842105 "Sarabun", sans-serif;
}

@media only screen and (min-width: 1200px) {
  .b-breadcrumbs__item:first-child .b-breadcrumbs__link {
    font: 600 1.625rem / 1.0769230769 "Sarabun", sans-serif;
  }
}

.b-breadcrumbs__link {
  color: #333333;
  text-transform: uppercase;
  font: 600 0.8125rem / 1.8461538462 "Sarabun", sans-serif;
}

@media only screen and (min-width: 1200px) {
  .b-breadcrumbs__link {
    font: 600 1.125rem / 1.3333333333 "Sarabun", sans-serif;
  }
}

.lds-roller {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 34px;
  height: 34px;
}

.lds-roller div {
  animation: lds-roller 1.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 17px 17px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #333333;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1)::after {
  top: 25px;
  left: 25px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2)::after {
  top: 27px;
  left: 23px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3)::after {
  top: 29px;
  left: 20px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4)::after {
  top: 29px;
  left: 16px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5)::after {
  top: 29px;
  left: 13px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6)::after {
  top: 27px;
  left: 10px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7)::after {
  top: 25px;
  left: 7px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8)::after {
  top: 23px;
  left: 5px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dropdown-trigger {
  position: relative;
}

.dropdown-trigger:hover + .dropdown-content {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: .3s;
}

.dropdown-trigger:hover + .dropdown-content--no-anim {
  transition: none;
}

.dropdown-trigger__arrow {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  fill: #000000;
}

.dropdown-trigger--pointer {
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  min-width: 130px;
  background-color: #ffffff;
  border: 1px dashed #333333;
  text-align: left;
  font: 400 0.9375rem / 1 hel-medium;
  color: #1a1919;
  opacity: 0;
  visibility: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.dropdown-content--top {
  bottom: 100%;
  top: auto;
}

.dropdown-content--right {
  right: 0;
  left: auto;
}

.dropdown-content--overlap {
  top: 0;
}

.dropdown-content--no-anim {
  display: none;
}

.dropdown-content li + li {
  border-top: 1px solid #ededed;
}

.dropdown-content li a {
  position: relative;
  display: block;
  padding: 10px 18px;
  text-decoration: none;
  color: #1a1919;
  transition: .3s;
}

.dropdown-content li a:hover, .dropdown-content li a.is-clicked {
  color: blue;
}

.dropdown-content li a.is-loading .lds-roller {
  display: inline-block;
}

.dropdown-content li a .lds-roller {
  right: 0;
  left: auto;
  user-select: none;
}

.dropdown-content .icon {
  margin-right: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination li + li {
  margin-left: 10px;
}

.pagination li.active a {
  background: #39b2ff;
}

.pagination li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 6px;
  background: #2383c5;
  background: linear-gradient(to right, #2383c5, #49abda);
  color: #ffffff;
  text-align: center;
  font: 20px/30px "Muli-Regular", Arial, sans-serif;
}

.pagination li.disabled span {
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 6px;
  background-color: #aaaaaa;
  color: #ffffff;
  text-align: center;
  font: 20px/30px "Muli-Regular", Arial, sans-serif;
}

.pagination--m-60 {
  margin: 60px 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* PAGES */
.main-title {
  padding: 0 0 2.375rem;
  margin: 0;
  text-align: center;
  position: relative;
  font-family: "Exo2-Thin";
  font-size: 4.9375rem;
  font-weight: normal;
  line-height: 1.1;
}

.main-title:before {
  content: "";
  display: inline-block;
  height: 2.125rem;
  background: url("../images/title-line.svg") 50% 100%/auto 100% no-repeat;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.main-title--s1 {
  padding-top: 5.625rem;
}

.main-title--s2 {
  padding-top: 10.625rem;
}

.main-title--s3 {
  padding-top: 9.6875rem;
}

@media screen and (max-width: 39.99875em) {
  .main-title {
    padding: 0 0 1.6875rem;
    font-size: 2.5rem;
  }
  .main-title:before {
    height: 1.4375rem;
  }
  .main-title--s2, .main-title--s3 {
    padding-top: 3.125rem;
  }
}

.middle-title {
  padding: 0;
  margin: 0;
  position: relative;
  font-family: "Exo2-SemiBold";
  font-size: 4.25rem;
  font-weight: normal;
  line-height: 1.1;
}

.middle-title:before {
  content: "";
  display: inline-block;
  width: 2.625rem;
  height: auto;
  background: url("../images/title-line-left.svg") right top/auto 100% no-repeat;
  top: 0.4375rem;
  left: auto;
  right: calc(100% + 14px);
  bottom: 0.3125rem;
  position: absolute;
}

.middle-title--s1 {
  margin-top: 7.8125rem;
}

@media screen and (max-width: 63.99875em) {
  .middle-title {
    padding-left: 2.1875rem;
    font-size: 1.875rem;
  }
  .middle-title:before {
    width: 20px;
    height: 52px;
    top: auto;
    left: 0;
    right: auto;
    bottom: 0;
  }
  .middle-title--s1 {
    margin-top: 2.8125rem;
  }
}

.shapes {
  position: relative;
  z-index: -1;
}

.shapes::before, .shapes::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.shapes::after {
  clear: both;
}

.shapes--home {
  z-index: 0;
}

.shapes__top-right {
  width: 28vw;
  height: 78vh;
  background: url("../images/shapes/shape-top-right.svg") 100% 50%/contain no-repeat;
  top: 17.5rem;
  left: auto;
  right: 0;
  bottom: auto;
  position: absolute;
}

.shapes__item-1 {
  width: 38.4375rem;
  height: 28.125rem;
  background: url("../images/shapes/shape-2.svg") center/contain no-repeat;
  top: -2.1875rem;
  left: -8.4375rem;
  right: auto;
  bottom: auto;
  position: absolute;
}

.shapes__item-1--p1 {
  top: -10.5rem;
}

.shapes__item-1--p2 {
  top: 0.125rem;
}

.shapes__item-1--p3 {
  left: -9.875rem;
}

.shapes__item-2 {
  width: 22.875rem;
  height: 16.75rem;
  background: url("../images/shapes/shape-2.svg") center/contain no-repeat;
  top: 0.625rem;
  left: 12%;
  right: auto;
  bottom: auto;
  position: absolute;
}

.shapes__item-2--p1 {
  top: 2.0625rem;
  left: -9.0625rem;
}

.shapes__item-3 {
  width: 62.625rem;
  height: 56.125rem;
  background: url("../images/shapes/shape-5.svg") center/contain no-repeat;
  top: -16.6875rem;
  left: -7.5rem;
  right: auto;
  bottom: auto;
  position: absolute;
}

.shapes__item-4 {
  width: 34.6875rem;
  height: 53.5rem;
  background: url("../images/shapes/shape-6.svg") center/contain no-repeat;
  top: 8.625rem;
  left: 0;
  right: auto;
  bottom: auto;
  position: absolute;
}

.shapes__item-5 {
  width: 30.4375rem;
  height: 70.0625rem;
  background: url("../images/shapes/shape-8.svg") center/contain no-repeat;
  top: 44rem;
  left: auto;
  right: 0;
  bottom: auto;
  position: absolute;
}

.shapes__item-6 {
  width: 11.6875rem;
  height: 12.375rem;
  background: url("../images/shapes/shape-10.svg") center/contain no-repeat;
  top: 1rem;
  left: -1.3125rem;
  right: 0;
  bottom: auto;
  position: absolute;
}

.shapes__item-7 {
  width: 11.9375rem;
  height: 11.9375rem;
  background: url("../images/shapes/shape-11.svg") center/contain no-repeat;
  top: 12.5rem;
  left: 8.4375rem;
  right: 0;
  bottom: auto;
  position: absolute;
}

.shapes__item-7--p1 {
  top: 4.6875rem;
  left: -5.8125rem;
}

.shapes__item-8 {
  width: 22.4375rem;
  height: 27.8125rem;
  background: url("../images/shapes/shape-2.svg") 100% 0/cover no-repeat;
  top: 7.6875rem;
  left: 0;
  right: auto;
  bottom: auto;
  position: absolute;
}

.shapes__item-9 {
  width: 11.6875rem;
  height: 12.375rem;
  background: url("../images/shapes/shape-10.svg") center/contain no-repeat;
  top: 46.5625rem;
  left: auto;
  right: 1.5625rem;
  bottom: auto;
  position: absolute;
}

.shapes__item-10 {
  width: 34.5rem;
  height: 53.5rem;
  background: url("../images/shapes/shape-6-reflected.svg") center/contain no-repeat;
  top: 55.625rem;
  left: auto;
  right: 0;
  bottom: auto;
  position: absolute;
}

.shapes__digital {
  width: 52vw;
  height: 118vh;
  top: 0;
  left: auto;
  right: 0;
  bottom: auto;
  position: absolute;
}

.shapes__digital img {
  margin: 0 0 0 auto;
}

@media screen and (max-width: 63.99875em) {
  .shapes__item-1, .shapes__item-2, .shapes__item-3, .shapes__item-4, .shapes__item-5, .shapes__item-6, .shapes__item-7, .shapes__item-8, .shapes__item-9, .shapes__item-10 {
    display: none;
  }
}

@media screen and (max-width: 39.99875em) {
  .shapes__top-right {
    display: none;
  }
  .shapes__digital {
    width: 87%;
    height: 70vh;
  }
}

.button {
  width: 12.1875rem;
  padding: 0.625rem;
  display: inline-block;
  color: #fff;
  text-align: center;
  background: url("../images/button-base-1.svg") center/contain no-repeat;
  border: 0;
  cursor: pointer;
  transition: .2s ease-in-out;
  font-family: "Muli-SemiBold";
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 3.125rem;
}

@media only screen and (min-width: 1200px) {
  .button {
    filter: drop-shadow(0 7px 17px #429edf);
  }
}

@media only screen and (min-width: 1200px) {
  .button:hover {
    filter: none;
  }
}

.button.small {
  width: auto;
  padding: 0.625rem 1.3125rem;
  background: url("../images/button-base-2.svg") center/contain no-repeat;
  font-family: "Muli-SemiBold";
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.625rem;
}

.button.white {
  color: #333;
  background: url("../images/button-base-3.svg") center/contain no-repeat;
}

.slick-prev,
.slick-next {
  width: 3.5625rem;
  height: 3.5625rem;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 0.7;
}

.slick-prev {
  background: url("../images/arrow-left-crew.svg") center/contain no-repeat;
}

.slick-prev:hover, .slick-prev:focus {
  background: url("../images/arrow-left-crew.svg") center/contain no-repeat;
}

@media only screen and (min-width: 1200px) {
  .slick-prev {
    left: -3.5625rem;
  }
}

.slick-next {
  background: url("../images/arrow-right-crew.svg") center/contain no-repeat;
}

.slick-next:hover, .slick-next:focus {
  background: url("../images/arrow-right-crew.svg") center/contain no-repeat;
}

@media only screen and (min-width: 1200px) {
  .slick-next {
    right: -3.5625rem;
  }
}

.image-custom__stroke {
  padding: 7px 9px 19px 9px;
  background: url("../images/shapes/shape-7-3.svg") center/100% 100% no-repeat;
}

.image-custom__stroke--s1 {
  padding: 15px 5px 19px 15px;
  background: url("../images/shapes/shape-12-2.svg") center/100% 100% no-repeat;
}

.image-custom__stroke--s2 {
  padding: 0px 0px 26px 0px;
  background: url("../images/shapes/shape-3-2.svg") center/100% 100% no-repeat;
}

.image-custom__stroke--s3 {
  padding: 0px 10px 35px 0px;
  background: url("../images/shapes/shape-4-2.svg") center/100% 100% no-repeat;
}

.clip-image {
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
}

.clip-image--s1 {
  width: 20.625rem;
  padding: 7px 9px 19px 9px;
  margin-top: 4.375rem;
}

.clip-image--s1:before {
  content: "";
  display: inline-block;
  background: url("../images/shapes/shape-7-3.svg") 50% calc(50% - 7px)/100% 100% no-repeat;
  top: 0.9375rem;
  left: 0;
  right: 0.9375rem;
  bottom: 0;
  position: absolute;
}

.clip-image--s1:after {
  content: "";
  display: inline-block;
  background: url("../images/shapes/shape-7-3.svg") center/100% 100% no-repeat;
  top: 0;
  left: -0.125rem;
  right: -1.1875rem;
  bottom: 2.5rem;
  position: absolute;
}

.clip-image--s2 {
  width: 23.125rem;
  padding: 5px 10px 15px 17px;
  margin-top: 4.375rem;
}

.clip-image--s2:before {
  content: "";
  display: inline-block;
  background: url("../images/shapes/shape-9-2.svg") 50% calc(50% - 7px)/100% 100% no-repeat;
  top: 0.8125rem;
  left: -0.625rem;
  right: 0;
  bottom: 1.5rem;
  position: absolute;
}

.clip-image--s3 {
  width: 21.1875rem;
  padding: 15px 5px 19px 15px;
  margin-top: 4.375rem;
}

.clip-image--s3:before {
  content: "";
  display: inline-block;
  background: url("../images/shapes/shape-12-2.svg") center/100% 100% no-repeat;
  top: -0.4375rem;
  left: 0.0625rem;
  right: 0.875rem;
  bottom: 0;
  position: absolute;
}

.clip-image--s3:after {
  content: "";
  display: inline-block;
  background: url("../images/shapes/shape-12-2.svg") center/100% 100% no-repeat;
  top: 0;
  left: 0;
  right: 0.6875rem;
  bottom: -0.125rem;
  position: absolute;
}

.clip-image__shape-1 {
  clip-path: url(#shape1);
}

.clip-image__shape-2 {
  clip-path: url(#shape2);
}

.clip-image__shape-3 {
  clip-path: url(#shape3);
}

@media screen and (max-width: 63.99875em) {
  .clip-image {
    margin-left: auto;
    margin-right: auto;
  }
}

.page-home {
  padding-bottom: 6.25rem;
}

@media only screen and (min-width: 1200px) {
  .page-home {
    padding-bottom: 12.5rem;
  }
}

.page-home__title-text {
  text-align: center;
  font-family: "Muli-SemiBold";
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.375rem;
}

@media screen and (max-width: 63.99875em) {
  .page-home {
    font-family: "Muli-Regular";
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 1.75rem;
  }
}

.digital-box {
  padding-top: 6.25rem;
  padding-bottom: 1.875rem;
  position: relative;
}

.digital-box::before, .digital-box::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.digital-box::after {
  clear: both;
}

.digital-box__title {
  font-family: "Exo2-SemiBold";
  font-size: 6.25rem;
  font-weight: normal;
  line-height: 1.2;
}

.digital-box__text {
  padding-top: 2.1875rem;
  font-family: "Muli-SemiBold";
}

.digital-box__more {
  padding-top: 3.625rem;
}

@media screen and (max-width: 1500px) {
  .digital-box {
    padding-top: 1.875rem;
  }
  .digital-box__title {
    font-size: 4.375rem;
  }
  .digital-box__more {
    padding-top: 1.75rem;
  }
}

@media screen and (max-width: 63.99875em) {
  .digital-box {
    font-family: "Muli-Regular";
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 1.75rem;
  }
  .digital-box__title {
    font-size: 3.125rem;
  }
}

@media screen and (max-width: 39.99875em) {
  .digital-box {
    padding-top: 87%;
  }
  .digital-box__title {
    font-size: 1.875rem;
  }
}

.innovation-box {
  min-height: 23.75rem;
  position: relative;
}

.innovation-box::before, .innovation-box::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.innovation-box::after {
  clear: both;
}

.innovation-box__image {
  max-width: 39%;
  margin-top: -1.875rem;
}

.innovation-box__wrap {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.innovation-box__wrap > .grid-container {
  height: 100%;
}

.innovation-box__wrap .custom-row {
  height: 100%;
}

.innovation-box__wrap .custom-cell {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

.innovation-box__title {
  font-family: "Exo2-SemiBold";
  font-size: 4.375rem;
  font-weight: normal;
  line-height: 1.2;
}

.innovation-box__text {
  padding-top: 1.875rem;
  font-family: "Muli-SemiBold";
}

@media screen and (max-width: 63.99875em) {
  .innovation-box {
    font-family: "Muli-Regular";
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 1.75rem;
  }
  .innovation-box__title {
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 39.99875em) {
  .innovation-box__image {
    max-width: 100%;
    margin-top: 0;
  }
  .innovation-box__image img {
    max-width: 90%;
    display: block;
  }
  .innovation-box__wrap {
    position: static;
  }
  .innovation-box__title {
    padding-top: 1.25rem;
  }
}

@keyframes services-hover {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

.services-menu {
  margin-top: 6.5625rem;
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.services-menu a[class^='menu-'] {
  height: 50%;
  flex: 0 0 50%;
  padding: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  color: #fff;
  font-family: "Exo2-Light";
  font-size: 3.125rem;
  font-weight: normal;
  line-height: 3.8125rem;
}

.services-menu a[class^='menu-']:hover {
  color: #76d9d1;
}

.services-menu a[class^='menu-']:before {
  content: "";
  display: inline-block;
  display: none;
  animation: services-hover .5s ease-in-out forwards;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: url("../images/bg-services-menu.png") center/cover no-repeat;
}

.services-menu a[class^='menu-']:hover:before {
  display: block;
}

.services-menu a[class^='menu-'].menu-1 {
  background: url("../images/services-square-1.svg") center no-repeat;
}

.services-menu a[class^='menu-'].menu-2 {
  background: url("../images/services-square-2.svg") center no-repeat;
}

.services-menu a[class^='menu-'].menu-3 {
  background: url("../images/services-square-3.svg") center no-repeat;
}

.services-menu a[class^='menu-'].menu-4 {
  background: url("../images/services-square-4.svg") center no-repeat;
}

.services-menu:before {
  content: "";
  display: inline-block;
  width: 9.0625rem;
  height: 9.0625rem;
  background: url("../images/plus.svg") 50% 50%/contain no-repeat;
  transform: translate(-50%, -50%);
  z-index: 1;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  position: absolute;
}

@media screen and (max-width: 63.99875em) {
  .services-menu a[class^='menu-'] {
    font-family: "Exo2-Light";
    font-size: 2.375rem;
    font-weight: normal;
    line-height: 1.2;
  }
}

@media screen and (max-width: 39.99875em) {
  .services-menu a[class^='menu-'] {
    height: auto;
    min-height: 7.5rem;
    flex: 1 0 100%;
    font-size: 1.875rem;
    background-size: 107% auto !important;
  }
  .services-menu:before {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .services-menu {
    height: 22.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .services-menu {
    height: 30rem;
  }
}

@media only screen and (min-width: 1024px) {
  .services-menu {
    height: 35.625rem;
  }
}

@media only screen and (min-width: 1200px) {
  .services-menu {
    height: 42.25rem;
  }
}

@media only screen and (min-width: 1440px) {
  .services-menu {
    height: 47.5rem;
  }
}

@media only screen and (min-width: 1920px) {
  .services-menu {
    height: 53.75rem;
  }
}

@media screen and (min-width: 640px) and (max-width: 1800px) {
  .services-menu a[class^='menu-'] {
    background-size: cover !important;
  }
}

.slider-team {
  padding: 5.625rem 3.125rem 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

@media only screen and (min-width: 1200px) {
  .slider-team {
    padding: 5.625rem 4.375rem 0;
  }
}

.slider-team__slide {
  flex: 0 0 32%;
  padding: 0 0.625rem;
  text-align: center;
}

.slider-team__image img {
  width: 100%;
  height: 187px;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .slider-team__image img {
    height: 285px;
  }
}

.slider-team__image-shape4 {
  max-width: 182px;
  clip-path: url("#shape4-mobile");
}

@media only screen and (min-width: 768px) {
  .slider-team__image-shape4 {
    max-width: 294px;
    clip-path: url("#shape4");
  }
}

.slider-team__image-shape5 {
  max-width: 199px;
  clip-path: url("#shape5-mobile");
}

@media only screen and (min-width: 768px) {
  .slider-team__image-shape5 {
    max-width: 304px;
    clip-path: url("#shape5");
  }
}

.slider-team__image-shape6 {
  max-width: 191px;
  clip-path: url("#shape6-mobile");
}

@media only screen and (min-width: 768px) {
  .slider-team__image-shape6 {
    max-width: 280px;
    clip-path: url("#shape6");
  }
}

.slider-team__name {
  padding-top: 2rem;
  font-family: "Exo2-SemiBold";
  font-size: 1.875rem;
  font-weight: normal;
  line-height: 1.1;
}

.slider-team__position {
  padding-top: 0.4375rem;
  font-family: "Muli-SemiBold";
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.1;
}

@media screen and (max-width: 39.99875em) {
  .slider-team {
    display: block;
  }
  .slider-team__slide {
    flex: 0 0 100%;
    padding: 0;
  }
  .slider-team__position {
    padding-bottom: 1.875rem;
  }
}

.page-projects {
  padding-bottom: 3.75rem;
}

@media only screen and (min-width: 1200px) {
  .page-projects {
    padding-bottom: 7.5rem;
  }
}

@media screen and (max-width: 63.99875em) {
  .page-projects {
    font-family: "Muli-Regular";
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 1.75rem;
  }
}

.project-panel {
  padding-top: 10.625rem;
}

.project-panel::before, .project-panel::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.project-panel::after {
  clear: both;
}

.project-panel__text {
  padding: 1.5625rem 0;
}

.project-panel__more {
  padding: 1.125rem 0 1.5625rem;
}

.project-panel__nav::before, .project-panel__nav::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.project-panel__nav::after {
  clear: both;
}

.project-panel__nav li {
  padding-top: 0.4375rem;
  color: #aaa;
  font-size: 1.375rem;
}

.project-panel__nav li.title {
  color: #333;
  font-family: "Exo2-SemiBold";
  font-size: 1.875rem;
  font-weight: normal;
  line-height: 1.1;
}

.project-panel__nav li ~ li.title {
  padding-top: 2.1875rem;
}

.project-panel__nav li a {
  color: #aaa;
}

.project-panel__nav li a:hover {
  color: #717171;
}

.project-panel__nav li a.site {
  color: #2181c4;
}

.project-panel__nav li a.site:hover {
  color: #3e9fe4;
}

@media screen and (max-width: 63.99875em) {
  .project-panel__nav {
    padding-top: 0.9375rem;
  }
  .project-panel__nav li {
    font-size: 1.125rem;
  }
  .project-panel__nav li.title {
    font-size: 1.25rem;
  }
}

.project-panel__image img {
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.project-panel__image--p1 {
  margin-left: -10.125rem;
}

.project-panel__image--p2 {
  margin-right: -6.3125rem;
}

@media screen and (max-width: 39.99875em) {
  .project-panel__image--p1, .project-panel__image--p2 {
    padding-top: 2rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.project-panel__row {
  padding-top: 2.6875rem;
}

.project-panel__row1 {
  padding-top: 2.375rem;
}

.project-panel__row2 {
  padding-top: 6.4375rem;
}

.project-panel--seo {
  padding-top: 2.1875rem;
}

@media screen and (max-width: 63.99875em) {
  .project-panel {
    padding-top: 3.4375rem;
  }
}

@media screen and (max-width: 39.99875em) {
  .project-panel__row, .project-panel__row1, .project-panel__row2 {
    padding-top: 0;
  }
  .project-panel__col-order {
    order: 1;
  }
}

.project-box {
  padding-top: 8.4375rem;
}

.project-box ~ .project-box {
  padding-top: 6.0625rem;
}

.project-box__title {
  text-align: center;
  font-family: "Exo2-SemiBold";
  font-size: 1.875rem;
  font-weight: normal;
  line-height: 1.1;
}

.project-box__text {
  padding-top: 1.6875rem;
  padding-bottom: 2.375rem;
  text-align: center;
  font-family: "Muli-SemiBold";
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.375rem;
}

.project-box__image img {
  width: 100%;
  border-radius: 1.6875rem;
  box-shadow: 0 0.9375rem 3.4375rem #bae3ff;
}

@media screen and (max-width: 39.99875em) {
  .project-box {
    padding-top: 3.125rem;
  }
  .project-box .project-box__image {
    padding: 0 0.9375rem;
  }
  .project-box .cell .project-box__image {
    padding: 0 0.3125rem 2.1875rem;
  }
}

.clients-box {
  padding: 3.625rem 3.125rem 4.375rem;
  margin-top: 1.875rem;
  text-align: center;
  background-color: #fff;
  border-radius: 1.3125rem;
  box-shadow: 0 0 2.3125rem #d9f1ff;
}

.clients-box::before, .clients-box::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.clients-box::after {
  clear: both;
}

@media only screen and (min-width: 1200px) {
  .clients-box {
    padding: 3.625rem 6.25rem 4.375rem;
    margin-top: 2.8125rem;
  }
}

.clients-box__image img {
  margin: 0 auto;
}

.clients-box__title {
  padding-top: 0.9375rem;
  text-align: center;
  font-family: "Exo2-SemiBold";
  font-size: 1.875rem;
  font-weight: normal;
  line-height: 1.1;
}

.clients-box__pos {
  padding-top: 0.4375rem;
  font-family: "Muli-SemiBold";
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.1;
}

.clients-box__text {
  padding-top: 2.125rem;
}

@media screen and (max-width: 39.99875em) {
  .clients-box {
    padding: 3.625rem 0.9375rem 3.125rem;
  }
}

.contact-us-box {
  padding-top: 2.5rem;
}

.contact-us-box::before, .contact-us-box::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.contact-us-box::after {
  clear: both;
}

@media only screen and (min-width: 1200px) {
  .contact-us-box {
    padding-top: 6.25rem;
  }
}

.contact-us-box__title {
  font-family: "Exo2-SemiBold";
  font-size: 6.25rem;
  font-weight: normal;
  line-height: 1.1;
}

.contact-us-box__text {
  padding-top: 1.4375rem;
}

.contact-us-box__feedback {
  padding-top: 2rem;
  text-align: center;
}

@media screen and (max-width: 39.99875em) {
  .contact-us-box {
    padding-top: 3.625rem;
  }
  .contact-us-box__title {
    font-size: 1.875rem;
  }
}

.page-services {
  padding-bottom: 6.25rem;
}

@media only screen and (min-width: 1200px) {
  .page-services {
    padding-bottom: 12.5rem;
  }
}

@media screen and (max-width: 63.99875em) {
  .page-services {
    font-family: "Muli-Regular";
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 1.75rem;
  }
}

.page-contact {
  padding-bottom: 6.25rem;
}

@media only screen and (min-width: 1200px) {
  .page-contact {
    padding-bottom: 12.5rem;
  }
}

@media screen and (max-width: 63.99875em) {
  .page-contact {
    font-family: "Muli-Regular";
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 1.75rem;
  }
}

.contact-box__title {
  padding-top: 4.75rem;
  font-family: "Exo2-SemiBold";
  font-size: 5.8125rem;
  font-weight: normal;
  line-height: 1.1;
}

.contact-box__text {
  padding: 1.5625rem 0 0.9375rem;
  font-family: "Muli-SemiBold";
}

.contact-box__item {
  padding: 0 0 0.625rem 3.125rem;
  margin-top: 1.25rem;
  background-position: 0 3px;
  background-repeat: no-repeat;
  background-size: 39px auto;
  font-family: "Exo2-SemiBold";
  font-size: 1.875rem;
  font-weight: normal;
  line-height: 1.1;
}

.contact-box__item a {
  color: #333;
}

.contact-box__item a:hover {
  color: #2181c4;
}

.contact-box__item.phone {
  background-image: url("../images/icons/phone.svg");
}

.contact-box__item.email {
  background-image: url("../images/icons/email.svg");
  background-position: 0 9px;
}

.contact-box__item.map {
  background-image: url("../images/icons/map.svg");
}

.contact-box__social {
  padding-top: 2.375rem;
  display: flex;
  flex-flow: row wrap;
}

.contact-box__social li {
  padding-right: 1.0625rem;
}

.contact-box__social a[class^='icon-'] {
  width: 5.4375rem;
  height: 5.4375rem;
  display: inline-block;
  transition: .2s ease-in-out;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.contact-box__social a[class^='icon-']:hover {
  opacity: 0.7;
}

.contact-box__social a[class^='icon-'][class='icon-facebook'] {
  background-image: url("../images/social/facebook.svg");
}

.contact-box__social a[class^='icon-'][class='icon-instagram'] {
  background-image: url("../images/social/instagram.svg");
}

.contact-box__social a[class^='icon-'][class='icon-twitter'] {
  background-image: url("../images/social/twitter.svg");
}

.contact-box__social a[class^='icon-'][class='icon-linkedin'] {
  background-image: url("../images/social/linkedin.svg");
}

@media screen and (max-width: 39.99875em) {
  .contact-box__social a[class^='icon-'] {
    width: 4rem;
    height: 4rem;
  }
}

.contact-box__form {
  padding: 5.3125rem 3.625rem;
  border-radius: 2.9375rem;
  background-color: #f2f9fe;
}

.contact-box__form .title {
  padding-bottom: 0.625rem;
  font-family: "Exo2-SemiBold";
  font-size: 3.4375rem;
  font-weight: normal;
  line-height: 1.1;
}

.contact-box__form label {
  padding: 1.0625rem 0 0.375rem 1.25rem;
  display: block;
  cursor: pointer;
  font-family: "Exo2-SemiBold";
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 1.1;
}

.contact-box__form .field {
  padding-top: 0.625rem;
}

.contact-box__form .field input[type='text'],
.contact-box__form .field input[id^='form-'],
.contact-box__form .field textarea {
  width: 100%;
  padding: 0.625rem 1.25rem;
  border-radius: 1.4375rem;
  border: 0;
  box-shadow: 0 5px 20px #c9e1f0;
  font-family: "Exo2-SemiBold";
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2.125rem;
}

.contact-box__form .field input[type='text']:focus,
.contact-box__form .field input[id^='form-']:focus,
.contact-box__form .field textarea:focus {
  padding: 0.5625rem 1.1875rem;
  border: 1px solid #22baee;
}

.contact-box__form .field input[type='text'].error-form,
.contact-box__form .field input[id^='form-'].error-form,
.contact-box__form .field textarea.error-form {
  padding: 0.5625rem 1.1875rem;
  border: 1px solid #ee6c0d;
}

.contact-box__form .field input[type='text'].error-form + .error-text,
.contact-box__form .field input[id^='form-'].error-form + .error-text,
.contact-box__form .field textarea.error-form + .error-text {
  display: block;
}

.contact-box__form .field textarea {
  height: 7.1875rem;
  resize: vertical;
}

.contact-box__form .error-text {
  padding: 0.4375rem 1.5625rem 0;
  display: none;
  color: #ee6c0d;
  font-family: "Muli-Regular";
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.1;
}

.contact-box__form .submit {
  padding-top: 1.875rem;
  text-align: center;
}

@media screen and (max-width: 39.99875em) {
  .contact-box__form {
    padding: 1.875rem 0.9375rem;
    border-radius: 2.375rem;
  }
  .contact-box__form .title {
    padding: 0 1.25rem;
    font-size: 1.875rem;
  }
}

@media screen and (max-width: 63.99875em) {
  .contact-box__form {
    margin-top: 4.0625rem;
  }
}

@media screen and (max-width: 39.99875em) {
  .contact-box__title {
    padding-top: 0;
    font-size: 1.875rem;
  }
  .contact-box__text {
    padding-top: 0.9375rem;
  }
  .contact-box__item {
    font-family: "Exo2-SemiBold";
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.7;
  }
  .contact-box__social {
    padding-top: 1.25rem;
    justify-content: space-around;
  }
  .contact-box__social li {
    padding-right: 0;
  }
}

.page-404 {
  padding-bottom: 3.125rem;
  text-align: center;
}

.page-404__box {
  min-height: 43.4375rem;
  padding: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  color: #fff;
  background: url("../images/shapes/404-shape.svg") 50% 50%/contain no-repeat;
}

.page-404__title-img {
  max-width: 50%;
}

.page-404__title {
  padding-top: 1.3125rem;
  color: #fff;
  font-family: "Exo2-SemiBold";
  font-size: 2.5rem;
  font-weight: normal;
  line-height: 1.1;
}

.page-404__go-home {
  padding-top: 3.3125rem;
}

@media screen and (min-width: 40em) and (max-width: 63.99875em) {
  .page-404__box {
    min-height: 31.25rem;
  }
}

@media screen and (max-width: 39.99875em) {
  .page-404__box {
    min-height: 18.75rem;
  }
  .page-404__title-img {
    max-width: 9.875rem;
  }
  .page-404__title {
    font-size: 1.1875rem;
  }
  .page-404__go-home {
    padding-top: 1.4375rem;
  }
}
