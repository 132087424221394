// Projects
.page-projects {
    padding-bottom: rem(60px);

    @include mq (desktop) {
        padding-bottom: rem(120px);
    }

    @include breakpoint(medium down) {
        @include fs (rem(18px), rem(28px), $muli-r);
    }
}

// Project Panel
.project-panel {
    padding-top: rem(170px);
    @include clearfix;

    &__text {
        padding: rem(25px) 0;
    }

    &__more {
        padding: rem(18px) 0 rem(25px);
    }

    &__nav {
        @include clearfix;
        li {
            padding-top: rem(7px);
            color: #aaa;
            font-size: rem(22px);
            &.title {
                color: $main-text-color;
                @include fs (rem(30px), 1.1, $exo-b);
            }
            & ~ li.title {
                padding-top: rem(35px);
            }

            a {
                @include link-color (#aaa,#717171);
                &.site {
                    @include link-color (#2181c4,#3e9fe4);
                }
            }
        }

        @include breakpoint(medium down) {
            padding-top: rem(15px);
            li {
                font-size: rem(18px);
                &.title {
                    font-size: rem(20px);
                }
            }
        }

    }

    &__image {
        // overflow: hidden;

        img {
            margin: 0 auto;
            position: relative;
            z-index: 1;
        }

        &--p1 {
            margin-left: rem(-162px);
        }
        &--p2 {
            margin-right: rem(-101px);
        }
        &--p1,
        &--p2 {
            @include breakpoint(small only) {
                padding-top: rem(32px);
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    &__row {
        padding-top: rem(43px);
    }
    &__row1 {
        padding-top: rem(38px);
    }
    &__row2 {
        padding-top: rem(103px);
    }

    &--seo {
        padding-top: rem(35px);
    }

    @include breakpoint(medium down) {
        padding-top: rem(55px);
    }
    @include breakpoint(small only) {
        &__row,
        &__row1,
        &__row2 {
            padding-top: 0;
        }
        &__col-order {
            order: 1;
        }
    }
}

// Project Box
.project-box {
    padding-top: rem(135px);
    & ~ .project-box {
        padding-top: rem(97px);
    }

    &__title {
        text-align: center;
        @include fs (rem(30px), 1.1, $exo-b);
    }

    &__text {
        padding-top: rem(27px);
        padding-bottom: rem(38px);
        text-align: center;
        @include fs (rem(18px), rem(22px), $muli-b)
    }

    &__image {
        img {
            width: 100%;
            border-radius: rem(27px);
            box-shadow: 0 rem(15px) rem(55px) #bae3ff;
        }
    }

    @include breakpoint(small only) {
        padding-top: rem(50px);

        .project-box__image {
            padding: 0 rem(15px);
        }

        .cell {
            .project-box__image {
                padding: 0 rem(5px) rem(35px);
            }
        }
    }
}

// Clients Box
.clients-box {
    padding: rem(58px) rem(50px) rem(70px);
    margin-top: rem(30px);
    text-align: center;
    background-color: #fff;
    border-radius: rem(21px);
    box-shadow: 0 0 rem(37px) #d9f1ff;
    @include clearfix;
    @include mq (desktop) {
        padding: rem(58px) rem(100px) rem(70px);
        margin-top: rem(45px);
    }

    &__image {
        img {
            margin: 0 auto;
        }
    }

    &__title {
        padding-top: rem(15px);
        text-align: center;
        @include fs (rem(30px), 1.1, $exo-b);
    }

    &__pos {
        padding-top: rem(7px);
        @include fs (rem(18px), 1.1, $muli-b);
    }

    &__text {
        padding-top: rem(34px);
    }

    @include breakpoint(small only) {
        padding: rem(58px) rem(15px) rem(50px);
    }
}

// Contact Us
.contact-us-box {
    padding-top: rem(40px);
    @include clearfix;

    @include mq (desktop) {
        padding-top: rem(100px);
    }

    &__title {
        @include fs (rem(100px), 1.1, $exo-b);
    }

    &__text {
        padding-top: rem(23px);
    }

    &__feedback {
        padding-top: rem(32px);
        text-align: center;
    }

    @include breakpoint(small only) {
        padding-top: rem(58px);

        &__title {
            font-size: rem(30px);
        }
    }
}
