
/* <ul class="inline-children--block sg-icons">
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg></li>
</ul>

Use `inline` when used with text:
```
<p>
	<svg class="icon icon--inline"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg>
	Lorem ipsum dolor sit amet, consectetur
	<svg class="icon icon--inline"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg>
	adipisicing elit.
	<svg class="icon icon--inline"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg>
</p>

Use `icon-text` for vertical alignment:
```
<div style="max-width: 10em">
	<span class="icon-text">
		<span class="icon-text__t">Icon text, description or title</span>
		<svg class="icon-text__i icon">
			<use xlink:href="/assets/img/sprite.svg#plus"></use>
		</svg>
	</span>
</div>
*/

$icon-size: 15px;

.icon {
	display: inline-block;
	vertical-align: middle;
	// width: $icon-size;
	// height: $icon-size;
	// fill: $c--dark-gray;

	&--inline {
		// y = line-height measurment was taken at
		// x =  how far off centre the baseline is, at `y`
		$x: 10;
		$y: 90;
		vertical-align: n($x/$y * 100%);
	}
}

.icon-text {
	display: inline-block;
	position: relative;

	&__i {
		width: $icon-size;
		height: $icon-size;
		position: absolute;
		top: 50%; right: 0;
		transform: translateY(-50%);

		&:first-child {
			right: auto; left: 0;
		}
	}

	&__t {
		display: inline-block;
		padding-right: calc($icon-size + $rel-spacing-unit--small);

		&:last-child {
			padding: {
				right: 0;
				left: calc($icon-size + $rel-spacing-unit--small);
			}
		}
	}
}
