.badge {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: $c--light-gray;
    color: $c--dark-gray;
    @include font(mukta-bold, 13px, 20px);
    &--not-available {
        color: $c--milano-red;
    }
    &--available {
        color: $c--persian-green;
    }
    &--active {
        color: $c--persian-green;
    }
    &--pending {
        color: $c--tangerine;
    }
    &--drafted {
        color: $c--dark-gray;
    }
    &--successfully {
        color: $c--japanese-laurel;
    }
    &--unsuccessfully {
        color: $c--milano-red;
    }
}
