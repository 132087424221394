//----------------------------------- General Styles

// Title large
.main-title {
	padding: 0 0 rem(38px);
	margin: 0;
	text-align: center;
	position: relative;
	@include fs (rem(79px), 1.1, $exo-t);

	@include before {
		height: rem(34px);
        background: url($baseUrl + 'title-line.svg') 50% 100% / auto 100% no-repeat;
		@include abs (auto);

	}

	&--s1 {
		padding-top: rem(90px);
	}

	&--s2 {
		padding-top: rem(170px);
	}

	&--s3 {
		padding-top: rem(155px);
	}

    @include breakpoint(small only) {
		padding: 0 0 rem(27px);
		font-size: rem(40px);
		&:before {
			height: rem(23px);
		}
		&--s2,
		&--s3 {
			padding-top: rem(50px);
		}
	}
}

// Title middle
.middle-title {
	padding: 0;
	margin: 0;
	position: relative;
	@include fs (rem(68px), 1.1, $exo-b);

	@include before {
		width: rem(42px);
		height: auto;
        background: url($baseUrl + 'title-line-left.svg') right top / auto 100% no-repeat;
		@include abs (rem(7px),auto,calc(100% + 14px),rem(5px));
	}

	&--s1 {
		margin-top: rem(125px);
	}

    @include breakpoint(medium down) {
		padding-left: rem(35px);
		font-size: rem(30px);
		&:before {
			width: 20px;
			height: 52px;
			top: auto;
			left: 0;
			right: auto;
			bottom: 0;
		}
		&--s1 {
			margin-top: rem(45px);
		}
	}
}


// Shapes
.shapes {
	position: relative;
	z-index: -1;
	@include clearfix;

	&--home {
		z-index: 0;
	}

	&__top-right {
		width: 28vw;
		height: 78vh;
		background: url($baseUrl + 'shapes/shape-top-right.svg') 100% 50% / contain no-repeat;
		@include abs (rem(280px),auto,0,auto);
	}

	// Index page
	&__item-1 {
		width: rem(615px);
		height: rem(450px);
		background: url($baseUrl + 'shapes/shape-2.svg') center / contain no-repeat;
		@include abs (rem(-35px),rem(-135px),auto,auto);

		&--p1 {
			top: rem(-168px);
		}
		&--p2 {
			top: rem(2px);
		}
		&--p3 {
			left: rem(-158px);
		}
	}

	&__item-2 {
		width: rem(366px);
		height: rem(268px);
		background: url($baseUrl + 'shapes/shape-2.svg') center / contain no-repeat;
		@include abs (rem(10px),12%,auto,auto);

		&--p1 {
			top: rem(33px);
			left: rem(-145px);
		}
	}

	&__item-3 {
		width: rem(1002px);
		height: rem(898px);
		background: url($baseUrl + 'shapes/shape-5.svg') center / contain no-repeat;
		@include abs (rem(-267px),rem(-120px),auto,auto);
	}

	&__item-4 {
		width: rem(555px);
		height: rem(856px);
		background: url($baseUrl + 'shapes/shape-6.svg') center / contain no-repeat;
		@include abs (rem(138px),0,auto,auto);
	}

	&__item-5 {
		width: rem(487px);
		height: rem(1121px);
		background: url($baseUrl + 'shapes/shape-8.svg') center / contain no-repeat;
		@include abs (rem(704px),auto,0,auto);
	}

	&__item-6 {
		width: rem(187px);
		height: rem(198px);
		background: url($baseUrl + 'shapes/shape-10.svg') center / contain no-repeat;
		@include abs (rem(16px),rem(-21px),0,auto);
	}

	&__item-7 {
		width: rem(191px);
		height: rem(191px);
		background: url($baseUrl + 'shapes/shape-11.svg') center / contain no-repeat;
		@include abs (rem(200px),rem(135px),0,auto);

		&--p1 {
			top: rem(75px);
			left: rem(-93px);
		}
	}

	&__item-8 {
		width: rem(359px);
		height: rem(445px);
		background: url($baseUrl + 'shapes/shape-2.svg') 100% 0 / cover no-repeat;
		@include abs (rem(123px),0,auto,auto);
	}

	&__item-9 {
		width: rem(187px);
		height: rem(198px);
		background: url($baseUrl + 'shapes/shape-10.svg') center / contain no-repeat;
		@include abs (rem(745px),auto,rem(25px),auto);
	}

	&__item-10 {
		width: rem(552px);
		height: rem(856px);
		background: url($baseUrl + 'shapes/shape-6-reflected.svg') center / contain no-repeat;
		@include abs (rem(890px),auto,0,auto);
	}




	&__digital {
		width: 52vw;
		height: 118vh;
		// background: url($baseUrl + 'pic-digital.png') 100% 0 / contain no-repeat;
		@include abs (0,auto,0,auto);

		img {
			margin: 0 0 0 auto;
		}

		// @include before {
		// 	background: url($baseUrl + 'shapes/shape-3-2.svg') 0 23px / contain no-repeat;
		// 	z-index: -1;
		// 	@include abs;
		// }
	}

	@include breakpoint(medium down) {
		&__item-1,
		&__item-2,
		&__item-3,
		&__item-4,
		&__item-5,
		&__item-6,
		&__item-7,
		&__item-8,
		&__item-9,
		&__item-10 {
			display: none;
		}
	}

	// Media
	@include breakpoint(small only) {
		&__top-right {
			display: none;
		}

		&__digital {
			width: 87%;
			height: 70vh;
		}
	}
}


// Buttons
.button {
	width: rem(195px);
	padding: rem(10px);
	display: inline-block;
	color: #fff;
	text-align: center;
	background: url($baseUrl + 'button-base-1.svg') center / contain no-repeat;
	border: 0;
	cursor: pointer;
	transition: .2s ease-in-out;
	@include fs (rem(20px), rem(50px), $muli-b);
	@include mq(desktop) {
		filter: drop-shadow(0 7px 17px rgba(66, 158, 223, 1));
	}
	&:hover {
		@include mq(desktop) {
			filter: none;
		}
	}
	// &:focus {
	// 	filter: drop-shadow(0 rem(7px) rem(17px) rgba(66, 158, 223, 1));
	// }

	&.small {
		width: auto;
		padding: rem(10px) rem(21px);
		background: url($baseUrl + 'button-base-2.svg') center / contain no-repeat;
		@include fs (rem(18px), rem(26px), $muli-b);
	}

	&.white {
		color: $main-link-color;
		background: url($baseUrl + 'button-base-3.svg') center / contain no-repeat;
	}
}



// Slick Styles
.slick-prev,
.slick-next {
	width: rem(57px);
	height: rem(57px);

	&:before {
		display: none;
	}

	&:hover {
		opacity: 0.7;
	}
}

.slick-prev {
	background: url($baseUrl + 'arrow-left-crew.svg') center / contain no-repeat;

	&:hover,
	&:focus {
		background: url($baseUrl + 'arrow-left-crew.svg') center / contain no-repeat;
	}

    @include mq (desktop) {
		left: rem(-57px);
	}
}

.slick-next {
	background: url($baseUrl + 'arrow-right-crew.svg') center / contain no-repeat;

	&:hover,
	&:focus {
		background: url($baseUrl + 'arrow-right-crew.svg') center / contain no-repeat;
	}

    @include mq (desktop) {
		right: rem(-57px);
	}
}

// Custom Image Stroke
.image-custom__stroke {
	padding: 7px 9px 19px 9px;
	background: url($baseUrl + 'shapes/shape-7-3.svg') center / 100% 100% no-repeat;

	&--s1 {
		padding: 15px 5px 19px 15px;
		background: url($baseUrl + 'shapes/shape-12-2.svg') center / 100% 100% no-repeat;
	}

	&--s2 {
		padding: 0px 0px 26px 0px;
		background: url($baseUrl + 'shapes/shape-3-2.svg') center / 100% 100% no-repeat;
	}

	&--s3 {
		padding: 0px 10px 35px 0px;
		background: url($baseUrl + 'shapes/shape-4-2.svg') center / 100% 100% no-repeat;
	}
}


// Clip Path Styles
.clip-image {
	overflow: hidden;
	position: relative;
	box-sizing: content-box;

	&--s1 {
		width: rem(330px);
		padding: 7px 9px 19px 9px;
		margin-top: rem(70px);

		@include before {
			background: url($baseUrl + 'shapes/shape-7-3.svg') 50% calc(50% - 7px) / 100% 100% no-repeat;
			@include abs (rem(15px),0,rem(15px),0);
		}
		@include after {
			background: url($baseUrl + 'shapes/shape-7-3.svg') center / 100% 100% no-repeat;
			@include abs (0,rem(-2px),rem(-19px),rem(40px));
		}
	}

	&--s2 {
		width: rem(370px);
		padding: 5px 10px 15px 17px;
		margin-top: rem(70px);

		@include before {
			background: url($baseUrl + 'shapes/shape-9-2.svg') 50% calc(50% - 7px) / 100% 100% no-repeat;
			@include abs (rem(13px),rem(-10px),0,rem(24px));
		}
	}

	&--s3 {
		width: rem(339px);
		padding: 15px 5px 19px 15px;
		margin-top: rem(70px);

		@include before {
			background: url($baseUrl + 'shapes/shape-12-2.svg') center / 100% 100% no-repeat;
			@include abs (rem(-7px),rem(1px),rem(14px));
		}
		@include after {
			background: url($baseUrl + 'shapes/shape-12-2.svg') center / 100% 100% no-repeat;
			@include abs (0,0,rem(11px),rem(-2px));
		}
	}

	&__shape-1 {
		clip-path: url(#shape1);
	}
	&__shape-2 {
		clip-path: url(#shape2);
	}
	&__shape-3 {
		clip-path: url(#shape3);
	}

	@include breakpoint(medium down) {
		margin-left: auto;
		margin-right: auto;
	}
}
