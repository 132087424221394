.socials {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @include mq(desktop) {
        justify-content: flex-start;
    };
    &__link {
        display: inline-block;
        width: 48px;
        height: 48px;
        padding: 12px 0;
        border-radius: 50%;
        background-color: $c--dark-gray;
        transition: .3s;
        &:hover {
            background-color: $c--persian-green;
        }
        & + & {
            @include mq(desktop) {
                margin-left: 35px;
            };
        }
    }
    &__icon {
        fill: $c--white;
    }
}
