.circle {
	border-radius: 50%;
}

.t-right {
    text-align: right;
}

.t-center {
    text-align: center;
}

.help-block {
	margin-left: 20px;
	color: $red-error;
	line-height: 20px;
	font-size: rem(16px);
	@include fs (rem(16px), 1.3, $muli-r);
}
