.pagination {
    display: flex;
    justify-content: center;
    li {
        & + li {
            margin-left: 10px;
        }
        &.active {
            a {
                background: #39b2ff;
            }
        }
        a {
            display: inline-block;
            width: 40px;
            height: 40px;
            padding: 5px;
            border-radius: 6px;
            @include background-gradient(#2383c5, #49abda, horizontal);
            color: $c--white;
            text-align: center;
            font: 20px/30px "Muli-Regular", Arial, sans-serif
        }
        &.disabled {
            span {
                display: inline-block;
                width: 40px;
                height: 40px;
                padding: 5px;
                border-radius: 6px;
                background-color: $c--medium-gray;
                color: $c--white;
                text-align: center;
                font: 20px/30px "Muli-Regular", Arial, sans-serif
            }
        }
    }
}

.pagination--m-60 {
    margin: 60px 0;
}
