$html-font-size: 16px;

// Default
$main-text-color:		#333;
$main-link-color:		#333;
$main-link-color-hover: #505050;
$dark-blue: 			#2181c4;
$red-error: 			#ee6c0d;


$baseUrl: 				'../images/';
/*
	Typography
*/

$exo-t: 'Exo2-Thin';
$exo-l: 'Exo2-Light';
$exo-b: 'Exo2-SemiBold';

$muli-r: 'Muli-Regular';
$muli-b: 'Muli-SemiBold';

// font sizes

$h--xxx-small: rem(16px);
$h--xx-small: rem(18px);
$h--x-small: rem(20px);
$h--small: rem(22px);
$h--medium: rem(24px);
$h--large: rem(28px);
$h--x-large: rem(36px);
$h--xx-large: rem(48px);
$h--xxx-large: rem(60px);

$p: rem(16px);
$p--small: rem(12px);
$p--medium: rem(18px);
$p--large: rem(24px);

// line-height ratios

$lh: 1.5;
$lh--x-small: 1.1;
$lh--small: 1.25;
$lh--large: 1.75;

// weights

$w--light: 300;
$w--normal: 400;
$w--medium: 500;
$w--semibold: 600;
$w--bold: 700;
$w--exbold: 800;
$w--black: 800;

/*
	Colours
*/

// social colours

$c--twitter: #55acee;
$c--facebook: #3b5998;
$c--youtube: #bb0000;
$c--pinterest: #cb2027;
$c--linkedin: #007bb5;
$c--instagram: #8a3ab9;

// other colours

$c--white: #ffffff;
$c--black: #000000;
$c--soft-black: #333;
$c--persian-green: #00a196;
$c--fire: #a13602;
$c--milano-red: #c4140f;
$c--tangerine: #e89902;
$c--japanese-laurel: #0baa0b;
$c--light-gray: #edeff3;
$c--medium-gray: #aaaaaa;
$c--dark-gray: #333333;

$input-placeholder-color: $c--medium-gray;
$input-border-color: $c--dark-gray;
$input-border-color-focus: $c--persian-green;
$select-border-color: $input-border-color;
$select-border-color-focus: $input-border-color-focus;
/*
	Layout
*/

// basic spacing units

$spacing-unit: rem(30px);
$spacing-unit--x-small: rem(10px);
$spacing-unit--small: rem(20px);
$spacing-unit--large: rem(40px);
$spacing-unit--x-large: rem(60px);

$rel-spacing-unit: 1em;
$rel-spacing-unit--small: .5em;

$global-radius: 2px;
/*
	Easing/Timing
*/

//	Sine
$ease--in-sine: cubic-bezier(.47,0,.745,.715);
$ease--out-sine: cubic-bezier(.39,.575,.565,1);
$ease--in-out-sine: cubic-bezier(.445,.05,.55,.95);

//	Quad
$ease--in-quad: cubic-bezier(.55,.085,.68,.53);
$ease--out-quad: cubic-bezier(.25,.46,.450,.94);
$ease--in-out-quad: cubic-bezier(.455,.03,.515,.955);

//	Cubic
$ease--in-cubic: cubic-bezier(.55,.055,.675,.19);
$ease--out-cubic: cubic-bezier(.215,.61,.355,1);
$ease--in-out-cubic: cubic-bezier(.645,.045,.355,1);

//	Quart
$ease--in-quart: cubic-bezier(.895,.03,.685,.22);
$ease--out-quart: cubic-bezier(.165,.84,.44,1);
$ease--in-out-quart: cubic-bezier(.77,0,.175,1);

//	Quint
$ease--in-quint: cubic-bezier(.755,.05,.855,.06);
$ease--out-quint: cubic-bezier(.23,1,.320,1);
$ease--in-out-quint: cubic-bezier(.86,0,.07,1);

//	Expo
$ease--in-expo: cubic-bezier(.95,.05,.795,.035);
$ease--out-expo: cubic-bezier(.19,1,.22,1);
$ease--in-out-expo: cubic-bezier(1,0,0,1);

//	Circ
$ease--in-circ: cubic-bezier(.6,.04,.98,.335);
$ease--out-circ: cubic-bezier(.075,.82,.165,1);
$ease--in-out-circ: cubic-bezier(.785,.135,.15,.86);

//	Back
$ease--in-back: cubic-bezier(.6,-.28,.735,.045);
$ease--out-back: cubic-bezier(.175,.885,.32,1.275);
$ease--in-out-back: cubic-bezier(.68,-0.55,.265,1.55);

//	Smooth fade
$ease--smooth-fade: cubic-bezier(.365,.005,.355,1);
