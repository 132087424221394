.custom-upload {
    position: relative;
    display: block;
    padding: 40px 30px;
    border: 1px dashed $c--medium-gray;
    background-color: $c--white;
    text-align: center;
    @include mq(desktop) {
        padding: 40px;
    };
    &.is-uploading {
        .custom-upload__loading {
            display: flex;
        }
        .lds-roller {
            display: inline-block;
        }
    }
    &.is-uploaded {
        padding: 0;
        border-color: transparent;
        .custom-upload__default {
            display: none;
        }
        .custom-upload__uploaded {
            display: flex;
        }
    }
    &__icon {
        margin-bottom: 14px;
        height: 38px;
        fill: none;
        stroke: $c--medium-gray;
        stroke-width:2;
        stroke-miterlimit:10;
    }
    &__desc {
        color: $c--dark-gray;
        @include font(mukta, 16px, 28px);
    }
    &__notice {
        color: $c--medium-gray;
        @include font(mukta, 15px, 28px);
    }
    &__default {

    }
    &__loading {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: $c--white;
    }
    &__loading-percent {
        color: $c--dark-gray;
        @include font(mukta, 16px, 28px);
    }
    &__loading-bar {
        position: relative;
        width: 80%;
        height: 10px;
        background-color: $c--light-gray;
    }
    &__loading-line {
        position: absolute;
        left: 0;
        top: 0;
        width: 20%;
        height: 100%;
        background-color: $c--black;
        transition: .2s;
    }
    &__uploaded {
        // position: absolute;
        // top: 0;
        // left: 0;
        // flex-flow: column;
        // align-items: center;
        // justify-content: center;
        display: none;
        width: 100%;
        height: 100%;
        background-color: $c--white;
        text-align: left;
    }
    &__img-thumb {
        max-height: 370px;
    }

    input[type="file"] {
        display: none;
        visibility: hidden;
        opacity: 0;
    }

    label {
        display: block;
        width: 100%;
        height: 107px;
        // height: 100%;
        cursor: pointer;
        background-size: 100% 80%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .lds-roller {
        display: none;
        @include center(both);
    }
}

.custom-upload-secondary {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    &__label {
        flex: 0 0 auto;
        display: inline-block;
        padding: 15px 24px;
        border-radius: 5px;
        cursor: pointer;
        background-color: $c--dark-gray;
        color: $c--white;
        text-transform: uppercase;
        @include font(mukta, 17px, 20px);
    }
    &__name {
        display: inline-block;
        // margin-left: 14px;
        padding-left: 14px;
        color: $c--dark-gray;
        @include font(mukta, 16px, 28px);
        @include truncate(100%);
    }

    input[type="file"] {
        display: none;
        visibility: hidden;
        opacity: 0;
    }
}
