.card {
    padding: 30px 20px;
    border: 1px solid $c--dark-gray;
    background-color: $c--white;
    @include mq(desktop) {
        padding: 60px;
    };
    &--disabled {
        opacity: .4;
    }
    &__head {
        margin-bottom: 16px;
    }
}

.radio-card {
    display: block;
    padding: 20px 18px;
    cursor: pointer;
    border: 1px solid $c--dark-gray;
    background-color: $c--white;
    @include mq(desktop) {
        padding: 24px;
    };
    &--disabled {
        opacity: .5;
        cursor: default;
        user-select: none;
        pointer-events: none;
        & * {
            cursor: default !important;
        }
    }
    &__tip {
        margin-left: 24px;
        color: $c--medium-gray;
        @include font(mukta, 13px, 20px);
    }
    &__desc {
        margin-top: 22px;
        @include mq(tablet) {
            margin-top: 28px;
            margin-left: 24px;
        };
    }
    &__row {
        margin-top: 22px;
        @include mq(tablet) {
            margin-top: 0;
        };
        & + & {
            @include mq(tablet) {
                margin-top: 12px;
            };
        }
    }
    &__title {
        color: $c--dark-gray;
        @include font(mukta-bold, 16px, 20px);
    }
    &__text {
        margin-top: 10px;
        color: $c--dark-gray;
        @include font(mukta, 16px, 24px);
        & > * {
            @include font(mukta, 16px, 24px);
        }
    }
    &__heading-2 {
        color: $c--medium-gray;
        text-transform: uppercase;
        @include font(mukta, 14px, 20px);
    }
    &__text-2 {
        color: $c--dark-gray;
        @include font(mukta-bold, 16px, 24px);
        & > * {
            @include font(mukta-bold, 16px, 24px);
        }
    }
    &__badge {
        margin-top: 14px;
    }
    &__actions {
        display: none;
        margin-top: 20px;
        @include mq(tablet) {
            margin-left: 24px;
        };
    }
    &__action-row {
        & + & {
            margin-top: 20px;
            @include mq(tablet) {
                margin-top: 0;
            };
        }
    }
    &.is-checked {
        .radio-card__actions {
            display: block;
        }
    }
}
