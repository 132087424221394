.subscribe-input {
    position: relative;
    &__input {
        width: 100%;
        padding: 18px 50px 18px 18px;
        border: 1px solid lighten($c--dark-gray, 11.2%);
        border-radius: 2px;
        background-color: $c--dark-gray;
        color: $c--white;
        text-transform: uppercase;
        @include font(mukta, 17px, 19px);
        @include mq(desktop) {
            @include font(mukta, 15px, 17px);
        };
    }
    &__btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 100%;
        padding: 12px;
        border-radius: 0 2px 2px 0;
        background-color: $c--persian-green;
    }
    &__icon {
        fill: transparent;
        stroke: $c--white
    }
}
