// <span class="radio-wrap">
//     <input id="rad" type="radio" name="radio" value="radio">
//     <label for="rad">Radio</label>
// </span>

.radio-row {
    & + & {
        margin-top: 16px;
    }
}

[type="radio"] {
    &:checked,
    &:not(:checked) {
        position: absolute;
        left: -9999px;
        & + label {
            position: relative;
            display: inline-block;
            padding-left: 24px;
            cursor: pointer;
            // line-height: 15px
            color: $c--soft-black;
            @include font(mukta, 16px, 18px);
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 1px;
                // top: 50%;
                // transform: translateY(-50%);
                width: 16px;
                height: 16px;
                border: 1px solid $input-border-color;
                border-radius: 100%;
                background: $c--white;
                transition: border-color .2s;
            }
            &::after {
                content: '';
                width: 8px;
                height: 8px;
                background-color: $c--white;
                position: absolute;
                top: 5px;
                left: 4px;
                border-radius: 50%;
                transition: .2s ease;
            }
        }
    }

    &:not(:checked) {

        & + label {
            &:hover {
                &::before {
                    border-color: $input-border-color;
                }
            }
            &::after {
                opacity: 0;
                transform: scale(0);
            }
        }
    }
    &:checked {
        & + label {
            &::before {
                background-color: $c--persian-green;
                border-color: $c--persian-green;
            }

            &::after {
                opacity: 1;
                transform: scale(1);
            }
        }

        & ~ .md-input {
            display: block;
        }

    }
}

.radio-wrap {
    display: inline-block;
    & + .radio-wrap {
        margin-left: 20px;
    }
}

.radio-label {
    display: inline-flex;
    &__cb {
        display: none;
        visibility: hidden;
        opacity: 0;
        &:checked {
            & ~ .radio-label__label {
                border-color: $c--dark-gray;
                .radio-label__checkmark {
                    display: inline-block;
                }
                .radio-label__plus {
                    display: none;
                }
            }
        }
    }
    &__label {
        display: inline-flex;
        align-items: center;
        padding: 8px 16px;
        border-radius: 5px;
        border: 1px solid transparent;
        background-color: $c--light-gray;
        cursor: pointer;
        transition: .2s;
    }
    &__title {
        color: $c--dark-gray;
        @include font(mukta-medium, 16px, 24px);
    }
    &__plus {
        position: relative;
        width: 17px;
        height: 17px;
        display: inline-block;
        margin-left: 10px;
        &::before, &::after {
            @include pseudo(inline-block, absolute);
            background-color: $c--medium-gray;
        }
        &::before {
            @include center(vertical);
            width: 100%;
            height: 1px;
        }
        &::after {
            @include center(horizontal);
            width: 1px;
            height: 100%;
        }
    }
    &__checkmark {
        // width: 14px;
        // height: 10px;
        display: none;
        margin-left: 10px;
    }
    &__svg {
        width: 17px;
        height: 10px;
        fill: none;
        stroke: $c--black;
        stroke-width: 2px;
    }

}
