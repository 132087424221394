.btn {
	display: inline-block;
	padding: 0;
	border: none;
	outline: none;
	cursor: pointer;
	background: none;
	text-align: center;
	text-decoration: none;
	&--s {
		padding: 8px 16px;
		font-size: rem(14px);
	}
	// &--m {
	// 	padding: 12px 20px;
	// 	font-size: rem(15px);
	// }
	&--l {
		padding: 12px 20px;
		font-size: rem(15px);
	}
	&--center {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	&--right {
		display: block;
		margin-left: auto;
		margin-right: 0;
	}
}

.btn-primary {
	min-width: 210px;
	padding: 8px 18px;
	border-radius: 10px;
	background-color: $c--persian-green;
    color: $c--white;
	text-transform: uppercase;
    @include font(mukta, 17px, 34px);
    transition: .3s;
	@include mq(desktop) {
		padding: 10px 18px;
		@include font(mukta, 23px, 35px);
	};
    &:hover,
	&:focus {
        background-color: darken($c--persian-green, 3%);
    }
	&:active {
		background-color: $c--persian-green;
	}
	&[disabled] {
		background-color: $c--medium-gray;
		cursor: not-allowed;
		user-select: none;
	}
	&__icon {
		display: inline-block;
		vertical-align: middle;
		width: 0;
		height: 16px;
		fill: green;
		transition: .2s;
	}
	&__text {
		display: inline-block;
		vertical-align: middle;
		margin-left: 0;
		transition: .2s;
	}
	&.is-active {
		.btn-primary__icon {
			width: 16px;
			transition: .2s .25s;
		}
		.btn-primary__text {
			margin-left: 6px;
			transition: .2s .25s;
		}
	}
}

.btn-secondary {
	padding: 6px 18px;
	border-radius: 5px;
	background-color: transparent;
	color: $c--dark-gray;
	border: 1px solid $c--dark-gray;
	text-transform: uppercase;
	letter-spacing: .04em;
	@include font(mukta, 15px, 35px);
	transition: .3s;
	&:hover,
	&:focus {
		background-color: $c--dark-gray;
		color: $c--white;
	}
	&[disabled] {
		background-color: transparent;
		color: $c--dark-gray;
		cursor: not-allowed;
		user-select: none;
	}
}

.btn-tertiary {
	padding: 10px 18px;
	border-radius: 30px;
	background-color: blue;
	color: $c--white;
	@include font(hel-light, 15px);
	text-transform: uppercase;
	transition: .3s;
	&:visited {
		color: $c--white;
	}
	&:hover,
	&:focus {
		// background-color: #5893ff;
		background-color: lighten(blue, 2.38%);
		color: $c--white;
	}
	&:active {
		// background-color: #477cef;
		background-color: darken(blue, 1.1%);
		color: $c--white;
	}
	&[disabled] {
		cursor: not-allowed;
		user-select: none;
		opacity: .6;
		&:hover,
		&:focus {
			background-color: blue;
			color: $c--white;
		}
		&:active {
			background-color: blue;
			color: $c--white;
		}
	}
	.icon-checked {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 30px;
		height: 30px;
		margin-right: 10px;
		border-radius: 50%;
		background-color: darken(blue, 10%);
		svg {
			@include center(both);
			width: 100%;
			height: 50%;
			fill: $c--white;
		}
	}
	.plus {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 30px;
		height: 30px;
		margin-right: 10px;
		border-radius: 50%;
		background-color: darken(blue, 10%);
		&::before,
		&::after {
			@include pseudo();
			@include center(both);
			background-color: $c--white;
		}
		&::before {
			width: 2px;
			height: 45%;
		}
		&::after {
			width: 45%;
			height: 2px;
		}
	}

	&__icon {
		display: inline-block;
		vertical-align: middle;
		width: 16px;
		height: 16px;
		margin-right: 6px;
		fill: $c--white;
		// transition: .2s;
	}
	&__text {
		display: inline-block;
		vertical-align: middle;
	}
}

.btn-close {
	position: absolute;
	top: 14px;
	right: 14px;
	width: 16px;
	height: 16px;

	&::before,
	&::after {
		@include pseudo;
		@include center(vertical);
		width: 100%;
		height: 1px;
		background-color: $c--black;
	}

	&::before {
		transform: rotate(45deg);
	}
	&::after {
		transform: rotate(-45deg);
	}
}
