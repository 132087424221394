.moda {
    animation: fadeIn .3s;
}

@keyframes fadeIn{
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}
