hr,
.hr {
	height: 1px;
	background-color: black;
	border: none;
}

.hr {
	&--grey-light {
		background-color: grey;
	}
}
